.otpscreen{
    /* background: transparent linear-gradient(180deg, #3381FF 0%, #253D80 100%) 0% 0% no-repeat padding-box; */
    background: #FFFFFF;
    opacity: 1;
    height: 100vh;
}

.verificationscreen{
    /* background: transparent linear-gradient(180deg, #3381FF 0%, #253D80 100%) 0% 0% no-repeat padding-box; */
    background: #FFFFFF;
    opacity: 1;
    height: 100vh;
}

.toplogos-otp{
    height: 7vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2.5%;
}

@media screen and (max-width: 540px){
    .toplogos-otp{
        height: 7vh;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 7%;
    }
}

.starlogo-otp{
    height: 83px;
    width: 72px;
}

@media screen and (max-width: 540px){
    .starlogo-otp{
        height: 83px;
        width: 72px;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .starlogo-otp{
        height: 83px;
        width: 72px;
    }
}


.toplogos-verification{
    height: 7vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2.5%;
}

@media screen and (max-width: 540px){
    .toplogos-verification{
        height: 7vh;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 7%;
    }
}

.starlogo-verification{
    height: 90px;
    width: 90px;
}

@media screen and (max-width: 540px){
    .starlogo-verification{
        height: 90px;
    width: 90px;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .starlogo-verification{
        height: 90px;
    width: 90px;
    }
}

.otplogin-txthdng{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    margin-top: 20vh;
}

@media screen and (max-width: 540px){
    .otplogin-txthdng{
        display: flex;
        justify-content: center;
        justify-content: space-around;
        margin-top: 20vh;

    }
}

@media (min-width: 740px) and (max-width: 940px){
    .otplogin-txthdng{
        display: flex;
        justify-content: center;
        justify-content: space-around;
        margin-top: 20vh;
    }
}

.loginwith-otptxt{
    text-align: center;
    font: normal normal normal 18px/18px Nobel-Regular;
    letter-spacing: 2.64px;
    color: #F94600;
    opacity: 1;
}

@media screen and (max-width: 540px){
    .loginwith-otptxt{
        text-align: center;
        font: normal normal normal 16px/18px Nobel-Regular;
        letter-spacing: 1.64px;
        color: #F94600;
        opacity: 1;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .loginwith-otptxt{
        text-align: center;
        font: normal normal normal 22px/18px Nobel-Regular;
        letter-spacing: 2.64px;
        color: #F94600;
        opacity: 1;
    }
}


.verificationlogin-txthdng{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    margin-top: 20vh;
}

@media screen and (max-width: 540px){
    .verificationlogin-txthdng{
        display: flex;
        justify-content: center;
        justify-content: space-around;
        margin-top: 20vh;

    }
}

@media (min-width: 740px) and (max-width: 940px){
    .verificationlogin-txthdng{
        display: flex;
        justify-content: center;
        justify-content: space-around;
        margin-top: 20vh;
    }
}

.loginwith-verificationtxt{
    text-align: center;
    font: normal normal normal 18px/18px Nobel-Regular;
    letter-spacing: 2.64px;
    color: #F94600;
    opacity: 1;
}

@media screen and (max-width: 540px){
    .loginwith-verificationtxt{
        text-align: center;
        font: normal normal normal 16px/18px Nobel-Regular;
        letter-spacing: 1.64px;
        color: #F94600;
        opacity: 1;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .loginwith-verificationtxt{
        text-align: center;
        font: normal normal normal 22px/18px Nobel-Regular;
        letter-spacing: 2.64px;
        color: #F94600;
        opacity: 1;
    }
}


.nameinputsfor-otp{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    margin-top: 5vh;
}

.nameinputbox::placeholder{
    color: #3F5EA5;
}

.nameinputbox{
    margin-left: 5%;
    margin-right: 2.5%;
    width: 20%;
    background: none;
    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-color: #3F5EA5;
    text-align: left;
        font: normal normal normal 14px/16px Nobel-Regular;
        letter-spacing: 0.56px;
        color: #3F5EA5;
        opacity: 1;
}

@media screen and (max-width: 540px){
    .nameinputbox{
        margin-left: 12%;
        width: 62%;
        background: none;
        outline: none;
        border-top: none;
        border-left: none;
        border-right: none;
        border-color: #3F5EA5;
        text-align: left;
        font: normal normal normal 14px/16px Nobel-Regular;
        letter-spacing: 0.56px;
        color: #3F5EA5;
        opacity: 1;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .nameinputbox{
        width: 40%;
        background: none;
        outline: none;
        border-top: none;
        border-left: none;
        border-right: none;
        border-color: #3F5EA5;
        text-align: left;
        font: normal normal normal 14px/16px Nobel-Regular;
        letter-spacing: 0.56px;
        color: #3F5EA5;
        opacity: 1;
    }
}

.numberinputsfor-otp{
    display: flex;
    justify-content: center;
    /* justify-content: space-around; */
    margin-top: 3vh;
    margin-right: 5%;
}

.numberfirst{
    margin-left: 5%;
    margin-right: 1%;
    font: normal normal normal 14px/16px Nobel-Regular;
    letter-spacing: 0.56px;
    color: #3F5EA5;
    opacity: 1;
}

@media screen and (max-width: 540px){
    .numberfirst{
        margin-right: 5%;
        font: normal normal normal 14px/16px Nobel-Regular;
        letter-spacing: 0.56px;
        color: #3F5EA5;
        opacity: 1;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .numberfirst{
        margin-left: 5%;
        margin-right: 3%;
        font: normal normal normal 14px/16px Nobel-Regular;
        letter-spacing: 0.56px;
        color: #3F5EA5;
        opacity: 1;
    }
}

.numberinputbox::placeholder{
    color: #3F5EA5;
}

.numberinputbox{
    width:22%;
    background: none;
    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-color: #3F5EA5;
    font: normal normal normal 14px/16px Nobel-Regular;
        letter-spacing: 0.56px;
        color: #3F5EA5;
        opacity: 1;
}

@media screen and (max-width: 540px){
    .numberinputbox{
        width: 65%;
        background: none;
        outline: none;
        border-top: none;
        border-left: none;
        border-right: none;
        border-color: #3F5EA5;
        font: normal normal normal 14px/16px Nobel-Regular;
        letter-spacing: 0.56px;
        color: #3F5EA5;
        opacity: 1;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .numberinputbox{
        width: 42%;
        background: none;
        outline: none;
        border-top: none;
        border-left: none;
        border-right: none;
        border-color: #3F5EA5;
        font: normal normal normal 14px/16px Nobel-Regular;
        letter-spacing: 0.56px;
        color: #3F5EA5;
        opacity: 1;
    }
}

::placeholder {
    color: #FFFFFF;
    opacity: 1; 
  }

.forverify-button{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    margin-top: 8vh;
}

.forverify-buttongoogle{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    margin-top: 40vh;
}

/* .verifybutton-txt{
    width: 25%;
    height: 40px;
    border: #097FF5;
    border-radius: 20px;
    background: transparent linear-gradient(0deg, #EC4215 0%, #FFB900 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    letter-spacing: 2.4px;
    color: #FFFFFF;
   opacity: 1;
   font: normal normal normal 14px/20px Nobel-BoldItalic;
   letter-spacing: 1.44px;
}

@media screen and (max-width: 540px){
    .verifybutton-txt{
        width: 75%;
        height: 40px;
        border: #097FF5;
        border-radius: 20px;
        background: transparent linear-gradient(0deg, #EC4215 0%, #FFB900 100%) 0% 0% no-repeat padding-box;
        opacity: 1;
        letter-spacing: 2.4px;
        color: #FFFFFF;
       opacity: 1;
       font: normal normal normal 14px/20px Nobel-BoldItalic;
       letter-spacing: 1.44px;
    }
    
}

@media (min-width: 740px) and (max-width: 940px){
    .verifybutton-txt{
        width: 50%;
        height: 50px;
        border: #097FF5;
        border-radius: 20px;
        background: transparent linear-gradient(0deg, #EC4215 0%, #FFB900 100%) 0% 0% no-repeat padding-box;
        opacity: 1;
        letter-spacing: 2.4px;
        color: #FFFFFF;
       opacity: 1;
       font: normal normal normal 20px/20px Nobel-BoldItalic;
       letter-spacing: 1.44px;
    }
} */

.verifybutton-txt {
    background: none;
    border: 2px solid;
    font: inherit;
    line-height: 1;
    margin: 0.5em;
    padding: 0.5em 1.5em;
    margin-left: 0.6% !important;
    margin-top: 0% !important;
    width: 23.7%;
    height: 7vh;
    border-radius: 24px;
    font-family: sans-serif;
    font-size: 20px;
    transition: background-color 0.5s ease;
  }

  @media screen and (max-width: 540px){
  .verifybutton-txt {
    background: none;
    border: 2px solid;
    font: inherit;
    line-height: 1;
    margin: 0.5em;
    padding: 0.5em 1.5em;
    width: 73.2%;
    height: 7vh;
    margin-left: 2% !important;
    margin-top: 0% !important;
    border-radius: 24px;
    font-family: sans-serif;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.5s ease;
  }
}

@media (min-width: 740px) and (max-width: 940px){
    .verifybutton-txt {
        background: none;
        border: 2px solid;
        font: inherit;
        line-height: 1;
        margin: 0.5em;
        padding: 0.5em 1.5em;
        margin-left: 1.6% !important;
        margin-top: 0% !important;
        width: 46.5%;
        height: 5vh;
        border-radius: 24px;
        font-family: sans-serif;
        font-size: 20px;
        transition: background-color 0.5s ease;
      }
}

  .verifybutton-txt {
    color: var(--color);
    transition: 0.25s;
  }
  


.verifybutton-txt:hover,
.verifybutton-txt:focus {
  /* box-shadow: inset 0 0 0 2em var(--hover); */
  border-color: var(--hover);
  color: #fff;
  box-shadow: inset 0em 0 1em 0 var(--color), inset 15em 1em 1em 5em var(--hover);
}

 @media screen and (max-width: 540px){
.verifybutton-txt:hover,
.verifybutton-txt:focus {
  /* box-shadow: inset 0 0 0 2em var(--hover); */
  border-color: var(--hover);
  color: #fff;
  box-shadow: inset 0em 0 1em 0 var(--color), inset 15em 1em 1em 5em var(--hover);
}
  }

  @media (min-width: 740px) and (max-width: 940px){
.verifybutton-txt:hover,
.verifybutton-txt:focus {
  /* box-shadow: inset 0 0 0 2em var(--hover); */
  border-color: var(--hover);
  color: #fff;
  box-shadow: inset 0em 0 1em 0 var(--color), inset 15em 1em 1em 5em var(--hover);
}
  }

.verifybutton-txt {
    /* --color: #FB902B;
    --hover: #DF4818; */
    --color: #0753EA;
    --hover: #3F5EA5;
  }

  .verifybutton-txt[push='1'] {
    animation: push 200ms 1;
  }

  @keyframes push {
    25% {
      transform: scale(0.95);
    }
    50% {
      transform: scale(0.90);
    }
    75% {
      transform: scale(0.85);
    }
    100% {
      transform: scale(0.80);
    }
  }

.fanplay-otp{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 2%;
    left: 45%;
}

@media screen and (max-width: 540px){
    .fanplay-otp{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 2%;
        left: 35%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .fanplay-otp{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 2%;
        left: 43%;
    }
}

.otp-fanplaylogo{
    /* margin-top: 3%; */
    height: 35px;
    width: 119px;
}

@media (min-width: 740px) and (max-width: 940px){
    .otp-fanplaylogo{
        /* margin-top: 3%; */
        height: 57px;
        width: 139px;
    }
}


.fanplay-verification{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 2%;
    left: 45%;
}

@media screen and (max-width: 540px){
    .fanplay-verification{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 2%;
        left: 35%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .fanplay-verification{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 2%;
        left: 43%;
    }
}

.verification-fanplaylogo{
    /* margin-top: 3%; */
    height: 35px;
    width: 119px;
}

@media (min-width: 740px) and (max-width: 940px){
    .verification-fanplaylogo{
        /* margin-top: 3%; */
        height: 57px;
        width: 139px;
    }
}


/* .verifying-numberhdng{
    display: flex;
    justify-content: center;
    justify-content: space-around;
} */

.verifyyournumber-txt{
    text-align: center;
    font: normal normal normal 17px/16px Nobel-Regular;
    letter-spacing: 0.56px;
    color: #3F5EA5;
    opacity: 1;
}

@media screen and (max-width: 540px){
    .verifyyournumber-txt{
        text-align: center;
        font: normal normal normal 17px/16px Nobel-Regular;
        letter-spacing: 0.56px;
        color: #3F5EA5;
        opacity: 1;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .verifyyournumber-txt{
        text-align: center;
        font: normal normal normal 22px/16px Nobel-Regular;
        letter-spacing: 0.56px;
        color: #3F5EA5;
        opacity: 1;
    }
}

.otpsended-number{
    text-align: center;
    font: normal normal normal 15px/13px Nobel-Regular;
    letter-spacing: 0.48px;
    color: #3F5EA5;
    opacity: 1;
}

@media screen and (max-width: 540px){
    .otpsended-number{
        text-align: center;
        font: normal normal normal 15px/13px Nobel-Regular;
        letter-spacing: 0.48px;
        color: #3F5EA5;
        opacity: 1;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .otpsended-number{
        text-align: center;
        font: normal normal normal 19px/13px Nobel-Regular;
        letter-spacing: 0.48px;
        color: #3F5EA5;
        opacity: 1;
    }
}

.numberinputsfor-otpverification{
    display: flex;
    justify-content: center;
    margin-top: 4vh;
    /* justify-content: space-around; */
}


.verification-inputui{
    /* background: transparent linear-gradient(0deg, #EC4215 0%, #FFB900 100%) 0% 0% no-repeat padding-box; */
    background: transparent linear-gradient(0deg, #3F5EA5 0%, #0753EA 100%) 0% 0% no-repeat padding-box;
    border-radius: 24px;
    opacity: 1;
    height: 5vh;
    width: 10%;
}

@media screen and (max-width: 540px){
    .verification-inputui{
        /* background: transparent linear-gradient(0deg, #EC4215 0%, #FFB900 100%) 0% 0% no-repeat padding-box; */
        background: transparent linear-gradient(0deg, #3F5EA5 0%, #0753EA 100%) 0% 0% no-repeat padding-box;
        border-radius: 24px;
        opacity: 1;
        height: 4.5vh;
        width: 38%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .verification-inputui{
        /* background: transparent linear-gradient(0deg, #EC4215 0%, #FFB900 100%) 0% 0% no-repeat padding-box; */
        background: transparent linear-gradient(0deg, #3F5EA5 0%, #0753EA 100%) 0% 0% no-repeat padding-box;
        border-radius: 24px;
        opacity: 1;
        height: 4vh;
        width: 22%;
    }
}

.numberinputboxverify{
    width: 100%;
    background: none;
    outline: none;
    border: none;
    /* border-color: #FFFFFF; */
    text-align: center;
    font: normal normal normal 20px/28px Nobel-Regular;
    letter-spacing: 0.56px;
    color: #FFFFFF;
    opacity: 1;
}

@media screen and (max-width: 540px){
    .numberinputboxverify{
        width: 100%;
        background: none;
        outline: none;
        border: none;
        /* border-color: #FFFFFF; */
        text-align: center;
        font: normal normal normal 20px/28px Nobel-Regular;
        letter-spacing: 0.56px;
        color: #FFFFFF;
        opacity: 1;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .numberinputboxverify{
        width: 100%;
        background: none;
        outline: none;
        border: none;
        /* border-color: #FFFFFF; */
        text-align: center;
        font: normal normal normal 20px/48px Nobel-Regular;
        letter-spacing: 0.56px;
        color: #FFFFFF;
        opacity: 1;
    }
}

.timerandreser-verification{
    display: flex;
    /* justify-content: center;
    justify-content: space-between; */
    margin-top: 0%;
}

.verification-timer{
    text-align: left;
    font: normal normal normal 12px/13px Nobel-Regular;
    letter-spacing: 0.48px;
    color: #3F5EA5;
    opacity: 0.69;
}

.resendotp-txt{
    text-align: right;
    margin-left: 40%;
    font: normal normal normal 12px/13px Nobel-Regular;
    letter-spacing: 0.48px;
    color: #F94600;
    opacity: 1;
}

@media screen and (max-width: 540px){
    .resendotp-txt{
        text-align: right;
        margin-left: 50%;
        font: normal normal normal 12px/13px Nobel-Regular;
        letter-spacing: 0.48px;
        color: #F94600;
        opacity: 1;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .resendotp-txt{
        text-align: right;
        margin-left: 50%;
        font: normal normal normal 15px/13px Nobel-Regular;
        letter-spacing: 0.48px;
        color: #F94600;
        opacity: 1;
    }
}

.otpcontainer{
  margin-top: 4vh;
  width: 50%;
  background: transparent linear-gradient(0deg, #EC4215 0%, #FFB900 100%) 0% 0% no-repeat padding-box;
  border-radius: 22px;
  padding: 1em 1.5em;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 0.8em;
  box-shadow: 0 45px 60px rgba(30, 22, 1, 0.3);
}

.otpnumber-tperinput{
  width: 100%;
  display: flex;
  justify-content: center;
  justify-content: space-around;
}

.inputforotp-number{
    height: 1em;
    width: 1em;
    background: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-color: #FFFFFF;
    /* border: 2px solid #dad9df; */
    outline: none;
    text-align: center;
    font-size: 20px;
    /* border-radius: 0.3em; */
    /* background-color: #ffffff; */
    outline: none;
    /*Hide number field arrows*/
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputforotp-number:disabled{
    color: #89888b;
}

.inputforotp-number:focus{
    border: 3px solid #ffb800;
}