body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Nobel-Book";
  src: url("./Font/NOBK____.ttf") format("truetype");
}

@font-face {
  font-family: "Nobel-BookItalic";
  src: url("./Font/NOBKI___.ttf") format("truetype");
}

@font-face {
  font-family: "Nobel";
  src: url("./Font/NOBD____.ttf") format("truetype");
}

@font-face {
  font-family: "Nobel-Regular";
  src: url("./Font/NOR_____.ttf") format("truetype");
}

@font-face {
  font-family: "Nobel-RegularItalic";
  src: url("./Font/NORI____.ttf") format("truetype");
}

@font-face {
  font-family: "Nobel-BlackItalic";
  src: url("./Font/NOBLI___.ttf") format("truetype");
}

@font-face {
  font-family: "Nobel-BoldItalic" ;
  src: url("./Font/NOBDI___.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: url("./Font/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Mart";
  src: url("./Font/Mart.otf") format("opentype");
}

@font-face {
  font-family: "Ubuntu";
  src: url("./Font/Ubuntu-Regular.ttf") format("truetype");
}
