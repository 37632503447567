/* .foruser-profile{
    background: transparent linear-gradient(180deg, #3381FF 0%, #253D80 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 96vh;
} */

/* .foruser-profile{
    position: relative;
} */

.toplogos-userprofile{
    height: 7vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1.5%;
}

@media screen and (max-width: 540px){
    .toplogos-userprofile{
        height: 7vh;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 3%;
    }
}

.starlogo-userprofile{
    height: 48px;
    width: 51px;
}

@media screen and (max-width: 540px){
    .starlogo-userprofile{
        height: 44px;
        width: 47px;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .starlogo-userprofile{
        height: 55px;
        width: 58px;
    }
}

.ipllogo-userprofile{
    height: 40px;
    width: 58px;
}

@media screen and (max-width: 540px){
    .ipllogo-userprofile{
        height: 36px;
        width: 54px;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .ipllogo-userprofile{
        height: 46px;
        width: 64px;
    }
}

.forshare-userprofile{
    display: flex;
    justify-content: flex-end;
}

.sharelogo-userprofile{
    width: 38px;
    height: 38px;
    margin-right: 3%;
}

@media screen and (max-width: 540px){
    .sharelogo-userprofile{
        width: 33px;
        height: 33px;
        margin-top: 1%;
        margin-right: 5%;
    }
}

.name-userprofile{
    margin-top: 5%;
    display: flex;
    justify-content: center;
    justify-content: space-around;
}

@media screen and (max-width: 540px){
    .name-userprofile{
        margin-top: 22%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .name-userprofile{
        margin-top: 25%;
    }
}

.userprofile-namechangetxt{
    text-align: center;
    font: normal normal normal 16px/18px Nobel-Regular;
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
}

@media (min-width: 740px) and (max-width: 940px){
    .userprofile-namechangetxt{
        text-align: center;
        font: normal normal normal 20px/18px Nobel-Regular;
        letter-spacing: 0px;
        color: #212121;
        opacity: 1;
    }
}

.inputfor-userprofile{
    height: 7vh;
    display: flex;
    justify-content: center;
    justify-content: space-around;
    position: relative;
}

@media screen and (max-width: 540px){
    .inputfor-userprofile{
        position: relative;
        height: 5vh;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .inputfor-userprofile{
        position: relative;
        height: 6vh;
        margin-top: 4%;
    }
}


.usernametxt-userprofile{
    text-align: center;
    font: normal normal normal 18px/5px ;
    letter-spacing: 0.32px;
    color: #212121;
    opacity: 1;
}

@media screen and (max-width: 540px){
.usernametxt-userprofile{
    text-align: center;
    font: normal normal normal 18px/29px ;
    letter-spacing: 0.32px;
    color: #212121;
    opacity: 1;
}
}

.search-modal{
    background:none;
    outline: none;
    opacity: 1;
    position: absolute;
    height: 24px;
    width: 65%;
    bottom: 84%;
    left: 44%;
}

@media screen and (max-width: 540px){
.search-modal{
    background:none;
    outline: none;
    opacity: 1;
    position: absolute;
    height: 24px;
    width: 45%;
    bottom: 83%;
    left: 30%;
}
}

@media (min-width: 740px) and (max-width: 940px){
    .search-modal{
        background:none;
        outline: none;
        opacity: 1;
        position: absolute;
        height: 24px;
        width: 45%;
        bottom: 90%;
        left: 40%;
    }
}


.closeimage-inmodel{
    position: absolute;
    left: 25.2%;
    top: 6.5%;
    height: 27px;
    width: 27px;
}

@media screen and (max-width: 540px){
    .closeimage-inmodel{
        position: absolute;
        left: 100%;
        top: 29.5%;
        height: 27px;
        width: 27px;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .closeimage-inmodel{
        position: absolute;
        left: 60.5%;
        top: 13.2%;
        height: 36px;
        width: 36px;
    }
}

.inputbox-username::placeholder{
    color: #212121;
}

.inputbox-username{
    text-align: center;
    color: #212121;
    /* padding-left: 2%; */
    width: 20%;
    height: 5vh;
    /* background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 24px;
    border-color: #FFFFFF;
    outline: none; */
    background-color: none;
        background: none;
        outline: none;
        border-top: none;
        border-left: none;
        border-right: none;
    opacity: 1;
}

@media screen and (max-width: 540px){
    .inputbox-username{
        text-align: center;
        color: #212121;
        /* padding-left: 3%; */
        width: 90%;
        height: 5vh;
        /* background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 24px;
        border-color: #FFFFFF;
        outline: none; */
        background-color: none;
        background: none;
        outline: none;
        border-top: none;
        border-left: none;
        border-right: none;
        opacity: 1;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .inputbox-username{
        text-align: center;
        color: #212121;
        /* padding-left: 3%; */
        width: 50%;
        height: 4vh;
        /* background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 24px;
        border-color: #FFFFFF;
        outline: none; */
        background-color: none;
        background: none;
        outline: none;
        border-top: none;
        border-left: none;
        border-right: none;
        opacity: 1;
    }
}


.editbtn-iconuser{
    position: absolute;
    left: 57.2%;
    top: 15.5%;
    height: 27px;
    width: 27px;
}

@media screen and (max-width: 540px){
    .editbtn-iconuser{
        position: absolute;
        left: 72%;
        top: 50.5%;
        height: 27px;
        width: 27px;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .editbtn-iconuser{
        position: absolute;
        left: 70.5%;
        top: 13.2%;
        height: 36px;
        width: 36px;
    }
}

.teamtxt-userprofile{
    margin-top: 2%;
    display: flex;
    justify-content: center;
    justify-content: space-around;
}

@media screen and (max-width: 540px){
    .teamtxt-userprofile{
        margin-top: 10%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .teamtxt-userprofile{
        margin-top: 10%;
    }
}

.userprofile-teamnametxt{
    text-align: center;
    font: normal normal normal 16px/18px Nobel-Regular;
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
}

@media (min-width: 740px) and (max-width: 940px){
    .userprofile-teamnametxt{
        text-align: center;
        font: normal normal normal 20px/18px Nobel-Regular;
        letter-spacing: 0px;
        color: #212121;
        opacity: 1;
    }
}

.forteamselcect-userprofile{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-content: space-around;
}


@media screen and (max-width: 540px){
    .teamlogo-nameuserprofile{
        margin-left: 3%;
        margin-right: 3%;
    }
}

.teamlogo-userprofile{
    height: 50px;
    width: 50px;
}

@media screen and (max-width: 540px){
    .teamlogo-userprofile{
        height: 50px;
        width: 50px;
        margin-left: 2.5%;
        margin-right: 2.5%;
        padding-top: 4%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .teamlogo-userprofile{
        height: 50px;
        width: 50px;
        margin-top: 2%;
    }
}

.teamname-userprofile{
    text-align: center;
    font: normal normal normal 16px/10px Nobel-Regular;
    letter-spacing: 0.32px;
    color: #212121;
    opacity: 1;
}

@media screen and (max-width: 540px){
    .teamname-userprofile{
        text-align: center;
        font: normal normal normal 15px/10px Nobel-Regular;
        letter-spacing: 0.32px;
        color: #212121;
        opacity: 1;
    }
}


.proceedbtn-userprofile{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    margin-top: 2%;
}

@media screen and (max-width: 540px){
    .proceedbtn-userprofile{
        margin-top: 7%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .proceedbtn-userprofile{
        margin-top: 7%;
    }
}

.foruserproceed-btn{
    width: 20%;
    height: 6vh;
    background: transparent linear-gradient(180deg, #FFC600 0%, #FF5A00 100%) 0% 0% no-repeat padding-box;
    border-radius: 24px;
    outline: none;
    border-color: #FF5A00;
    text-align: center;
    font: normal normal normal 20px/23px Nobel-Regular;
    letter-spacing: 0.4px;
    color: #212121;
    text-transform: uppercase;
    opacity: 1;
}

@media screen and (max-width: 540px){
    .foruserproceed-btn{
        width: 60%;
        height: 6vh;
        background: transparent linear-gradient(180deg, #FFC600 0%, #FF5A00 100%) 0% 0% no-repeat padding-box;
        border-radius: 24px;
        outline: none;
        border-color: #FF5A00;
        text-align: center;
        font: normal normal normal 20px/23px Nobel-Regular;
        letter-spacing: 0.4px;
        color: #212121;
        text-transform: uppercase;
        opacity: 1;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .foruserproceed-btn{
        width: 40%;
        height: 6vh;
        background: transparent linear-gradient(180deg, #FFC600 0%, #FF5A00 100%) 0% 0% no-repeat padding-box;
        border-radius: 24px;
        outline: none;
        border-color: #FF5A00;
        text-align: center;
        font: normal normal normal 21px/23px Nobel-Regular;
        letter-spacing: 0.4px;
        color: #212121;
        text-transform: uppercase;
        opacity: 1;
    }
}

.fanplay-userprofile{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 2%;
    left: 45%;
}

@media screen and (max-width: 540px){
    .fanplay-userprofile{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 2%;
        left: 35%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .fanplay-userprofile{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 2%;
        left: 43%;
    }
}

.userprofile-fanplaylogo{
    /* margin-top: 3%; */
    height: 35px;
    width: 119px;
}

@media (min-width: 740px) and (max-width: 940px){
    .userprofile-fanplaylogo{
        /* margin-top: 3%; */
        height: 57px;
        width: 139px;
    }
}

/* @media screen and (max-width: 540px){
    .userprofile-fanplaylogo{
        margin-top: 20%;
    }
} */


