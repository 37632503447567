.entire-hrsession{
    display: flex;
    justify-content: center;
    margin-top: 2%;
    height: 19px;
}

.hrrecording-imag{
    position: relative;
    bottom: 47%;
    z-index: 1;
    height: 134px;
    width: 147px;
}

@media screen and (max-width: 540px){
    .entire-hrsession{
        height: 0px;
        margin-top:13%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .entire-hrsession{
        height: 0px;
        margin-top:15%;
    }
}

.toplogos-index{
    height: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2.5%;
    padding-bottom: 0.5%;
}

@media screen and (max-width: 540px){
    .toplogos-index{
        height: 6.5vh;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 7%;
    }
}

.starlogo-index{
    height: 37px;
    width: 139px;
}

@media screen and (max-width: 540px){
    .starlogo-index{
        height: 37px;
        width: 139px;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .starlogo-index{
        height: 37px;
        width: 139px;
    }
}

.ipllogo-index{
    height: 40px;
    width: 58px;
}

@media screen and (max-width: 540px){
    .ipllogo-index{
        height: 36px;
        width: 54px;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .ipllogo-index{
        height: 46px;
        width: 64px;
    }
}


/* @media(min-width: 1200px) and (max-height: 850px){} */

.heartrate-recording-session{
    position: relative;
    display: flex;
    justify-content: flex-start;
    justify-content: space-around;
    /* background: #274B9F 0% 0% no-repeat padding-box; */
    /* background: rgba(255, 255, 255, 0.55) 0% 0% no-repeat padding-box; */
    background: transparent linear-gradient(0deg, rgba(63, 94, 165, 1) 0%, rgba(7, 83, 234, 1) 100%) 0% 0% no-repeat padding-box;    /* mix-blend-mode: darken; */
    margin-top: 0%;
    border-radius: 16px;
    width: 90%;
    height: auto;
    /* opacity: 0.8; */
    margin-right: 0%;
}

@media screen and (max-width: 540px){
    .heartrate-recording-session{
        position: relative;
        display: flex;
        justify-content: center;
        justify-content: space-around;
        width:98%;
        height: auto;
        padding-bottom: 4%;
        margin-top: 0%;
        margin-right: 1%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .heartrate-recording-session{
        width:100%;
        height: 16vh;
        padding-bottom: 4%;
        margin-top: 0%;
        margin-right: 1%;
    }
}

.current-value{
    visibility: hidden;
}

/* .speedometer{
    height: 180px;
} */

/* .speedometer-setting{
    height: 140px;
} */

.forlove-image{
    position: absolute;
    bottom:38.03%;
    left: 38.4%;
    height: 36px;
    width: 36.5px;
}

@media screen and (max-width: 540px){
    /* .forlove-image{
        position: relative;
        bottom: 78%;
        left: 15.5%;
        padding-bottom: 0px;
    } */
    .forlove-image{
        position: absolute;
        bottom: 39.4%;
        left: 39.9%;
        padding-bottom: 0px;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .forlove-image{
        position: relative;
        bottom: 79%;
        left: 13.5%;
        /* bottom:110% ;
        left: 24%; */
        padding-bottom: 0px;
    }
}

.forecg-image{
    position: absolute;
    bottom: 15%;
    width: 90.7%;
}

@media screen and (max-width: 540px){
    .forecg-image{
        position: absolute;
        bottom: 10.5%;
        width: 93%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .forecg-image{
        position: absolute;
        bottom: 42%;
        width: 21%;
    }
}

.recording-image{
    margin-left: 5%;
    width: 90%;
    height: 20vh;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 16px;
    opacity: 1;
}

@media screen and (max-width: 540px){
    .recording-image{
        margin-left: 3%;
        width: 100%;
        height: 20vh;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 16px;
        opacity: 1;
    }
}

.hr-recording-img{
    margin-top: 4vh;
    margin-left: 6vh;
    margin-right: 6vh;
}

#output{
    position: absolute;
    bottom: 39.5%;
    left: 37.4%;
    margin-top: 0px;
    width: 2px;
    height: 2px;
}

@media screen and (max-width: 540px){
    /* #output{
        position: relative;
        bottom: 80%;
        left:39%;
        margin-top: 0px;
        width: 2px;
        height: 2px;
    } */
    #output{
        position: absolute;
        bottom: 40.5%;
        left:39%;
        margin-top: 0px;
        width: 2px;
        height: 2px;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    #output{
        position: relative;
        bottom: 81%;
        left:36%;
        /* bottom: 112.5%;
        left:41%; */
        margin-top: 0px;
        width: 2px;
        height: 2px;
    }
}


#heartrate{
    height: 5px;
    padding-left: 0px;
    text-align: center;
    padding-top: 5px;
    font: normal normal bold 24px/25px Nobel;
    letter-spacing: 1.44px;
    color: #FFFFFF;
    /* color: #253D80; */
    opacity: 1;
}

@media screen and (max-width: 540px){
    #heartrate{
        text-align: center;
        font: normal normal bold 24px/5px Nobel;
        letter-spacing: 1.44px;
        color: #FFFFFF;
        /* color: #253D80; */
        opacity: 1;
        padding-top: 3%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    #heartrate{
        text-align: center;
        font: normal normal bold 24px/5px Nobel;
        letter-spacing: 1.44px;
        color: #FFFFFF;
        /* color: #253D80; */
        opacity: 1;
        padding-top: 3%;
    }
}

#heartrateses{
    text-align: center;
    padding-top: 5px;
    font: normal normal bold 24px/25px Nobel;
    letter-spacing: 1.44px;
    color: #FFFFFF;
    /* color: #253D80; */
    opacity: 1;
}

#heartrates{
    /* padding-left: 45px; */
    text-align: center;
    padding-top: 8px;
    font: normal normal normal 14px/0px Nobel-Regular;
    letter-spacing: 0.28px;
    color: #FFFFFF;
    /* color: #253D80; */
    opacity: 1;
}

@media screen and (max-width: 540px){
    #heartrateses{
        text-align: center;
        padding-top: 0px;
        font: normal normal bold 24px/5px Nobel;
        letter-spacing: 1.44px;
        color: #FFFFFF;
        /* color: #253D80; */
        opacity: 1;
        padding-top: 3%;
    }
    
}

@media screen and (max-width: 540px){
    #heartrates{
        text-align: center;
        font: normal normal normal 18px/10px Nobel-Regular;
        letter-spacing: 0.28px;
        color: #FFFFFF;
        /* color: #253D80; */
        opacity: 1;
        padding-top: 3%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    #heartrateses{
        text-align: center;
        padding-top: 0px;
        font: normal normal bold 24px/5px Nobel;
        letter-spacing: 1.44px;
        color: #FFFFFF;
        /* color: #253D80; */
        opacity: 1;
        padding-top: 3%;
    }
    
}

@media (min-width: 740px) and (max-width: 940px){
    #heartrates{
        text-align: center;
        font: normal normal normal 18px/12px Nobel-Regular;
        letter-spacing: 0.28px;
        color: #FFFFFF;
        /* color: #253D80; */
        opacity: 1;
        padding-top: 3%;
    }
}

/* #output{
    height: 130px;
} */


.Main-header{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    height: 6.5vh;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
}

@media screen and (max-width: 540px){
    .Main-header{
        display: flex;
        justify-content: center;
        justify-content: space-around;
        height: 4vh;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .Main-header{
        height: 3.5vh;
    }
}

/* .fanplaylogo-headerdiv{
    width: 50%;
    display: flex;
    justify-content: flex-start;
} */

.header-fanplayimg{
    padding-top: 5%;
    /* padding-left: 2%; */
    width: 146px;
    height: 30px;
}

@media screen and (max-width: 540px){
    .header-fanplayimg{
        padding-top: 4%;
        padding-left: 4%;
        width: 98px;
        height: 21px;
    }
}

.starsportlogo-headerdiv{
    width: 50%;
    display: flex;
    justify-content: flex-end;
}

.header-starsportsimg{
    padding-right: 2%;
    padding-top: 1%;
    width: 8%;
    height: 7vh;
}

@media screen and (max-width: 540px){
    .header-starsportsimg{
        padding-right: 4%;
        padding-top: 2%;
        width: 46px;
        height: 34px;
    }
}

.text{
    /* padding-top: 2%; */
    display: flex;
    justify-content: center;
    justify-content: space-around;
    height: 60px;
    margin-top: 0%;
    padding-top: 0%;
}

@media screen and (max-width: 540px){
    .text{
        margin-top: 0.5%;
        margin-left: 1%;
        margin-right: 1%;
        height: 87px;
    }
}

.formaintext-div{
    /* background: rgba(0, 0, 0, 0.5); */
    background: rgba(8, 38, 109, 0.849);
    /* background: #274B9F 0% 0% no-repeat padding-box; */
    border-radius: 16px;
    opacity: 1;
    margin: 0px;
    width: 82%;
    height: 58px;
    animation-name: zoom-in-zoom-out ;
    /* animation: zoom-in-zoom-out 1s ease infinite; */
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
}

@media screen and (max-width: 540px){
    .formaintext-div{
        /* background: rgba(0, 0, 0, 0.5); */
        background: rgba(8, 38, 109, 0.849);
        border-radius: 16px;
        opacity: 1;
        margin: 0px;
        width: 92%;
        height: 97px;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .formaintext-div{
        /* background: rgba(0, 0, 0, 0.5); */
        background: rgba(8, 38, 109, 0.849);
        border-radius: 16px;
        opacity: 1;
        margin: 0px;
        width: 92%;
        height: 74px;
    }
}

@keyframes zoom-in-zoom-out{
    0% {
        transform: scale(1, 1);
      }
      50% {
        transform: scale(1.05, 1.05);
      }
      100% {
        transform: scale(1, 1);
      }
}

.maintext-style{
    padding: 0px 20px;
    text-align: center;
    font: normal normal normal 18px/15px Nobel-Regular;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}


@media screen and (max-width: 540px){
    .maintext-style{
        padding: 0px 20px;
        text-align: center;
        font: normal normal normal 16px/16px Nobel-Regular;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
    }
}

.close-image{
    position: absolute;
    bottom: 70.5%;
    left: 98.5%;
}

@media screen and (max-width: 540px){
    .close-image{
        position: absolute;
        bottom: 83.5%;
        left: 95.5%;
    }
}


/* .maintext-style{
    animation-duration: 1s;
    animation-name: pulse;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
} */

.entire-body{
    /* backgroundImage: `url(https://fangurudevstrg.blob.core.windows.net/quiz/main_screen.png)` */
    /* background: transparent linear-gradient(180deg, #3381FF 0%, #253D80 100%) 0% 0% no-repeat padding-box; */
    background: #FFFFFF;
    overflow-y: hidden;
    margin-top: 0px;
    min-height: 100vh;
    height: auto;
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
    background-attachment: fixed
}

@media screen and (max-width: 540px){
    .entire-body{
        /* background: transparent linear-gradient(180deg, #3381FF 0%, #253D80 100%) 0% 0% no-repeat padding-box; */
        background: #FFFFFF;
        margin-top: 0px;
        height: auto;
        background-repeat:no-repeat;
        background-size:cover;
        background-position:center;
        background-attachment: fixed;
        padding-bottom: 10px;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .entire-body{
        /* background: transparent linear-gradient(180deg, #3381FF 0%, #253D80 100%) 0% 0% no-repeat padding-box; */
        background: #FFFFFF;
        margin-top: 0px;
        height: 100vh;
        background-repeat:no-repeat;
        background-size:cover;
        background-position:center;
        background-attachment: fixed;
        padding-bottom: 10px;
    }
}

.index-userprofile{
    margin-top: 2%;
}

@media screen and (max-width: 540px){
    .index-userprofile{
        margin-top: 2%;
        /* height: 4vh; */
    }
    
}

.index-secondline{
    /* overflow-y: hidden; */
    margin-right: 1%;
    padding-top: 1%;
    display: flex;
    justify-content: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    height: auto;
}

@media screen and (max-width: 540px){
    .index-secondline{
        padding-top: 0%;
        display: flex;
        justify-content: flex-start;
        justify-content: space-around;
        flex-wrap: wrap;
        height: auto;
        padding-bottom: 2px;
        padding-top: 0%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .index-secondline{
        margin-top: 0%;
        display: flex;
        justify-content: flex-start;
        justify-content: space-around;
        flex-wrap: wrap;
        height: auto;
        /* padding-bottom: 2px; */
        padding-bottom: 3%;
        padding-top: 0%;
    }
}

.secondline-hr{
    z-index: 5;
    margin-top: 7%;
    width: 35%;
}

@media screen and (max-width: 540px){
    .secondline-hr{
        z-index: 5;
        display: flex;
        justify-content: center;
        justify-content: space-around;
        height:auto;
        width: 100%;
        margin-top: 3%;
        padding-bottom: 4%;
        /* margin-bottom: 2%; */
    }

}

@media (min-width: 740px) and (max-width: 940px){
    .secondline-hr{
        z-index: 5;
        height:auto;
        width: 50%;
        margin-top: 2%;
        padding-bottom: 4%;
        /* margin-bottom: 2%; */
    }

}

@media screen and (max-width: 540px){
    .secondlines-aligns{
        width: 98%;
        margin-top: 0%;
        /* height: 24vh; */
    }

}

@media (min-width: 740px) and (max-width: 940px){
    .secondlines-aligns{
        width: 100%;
        margin-top: 0%;
        /* height: 24vh; */
    }
}

/* @media (min-width: 740px) and (max-width: 940px){
    .secondlines-aligns{
        width: 98%;
        margin-top: 0%;
    }

} */

/* .secondline-myfanemote{
    margin-top: 2%;
}

@media screen and (max-width: 540px){
    .secondline-myfanemote{
        margin-top: 4%;
    }
} */

.cheering-txt{
    text-align: center;
    font: normal normal normal 16px/0px Nobel-Regular;
    letter-spacing: 0.32px;
    color: #212121;
    opacity: 1;
    padding-top: 10px;
}

.secondline-Teams{
    /* display: flex;
    justify-content: center;
    justify-content: space-around; */
    width: 30%;
    margin-top: 7%;
}

@media screen and (max-width: 540px){
    .secondline-Teams{
        /* display: flex;
        justify-content: center;
        justify-content: space-around; */
        width: 95%;
        margin-top: 4%;
        height: auto;
        padding-bottom: 1.5%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .secondline-Teams{
        width: 100%;
        margin-top: 0%;
        height: auto;
        padding-bottom: 0%;
    }
}

.forindialogo-div{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    padding-top: 3%;
}

.onlyindialogo{
    height: 90px;
    width: 90px;
}

@media screen and (max-width: 540px){
    .onlyindialogo{
        height: 75px;
        width: 75px;
    }
}

.secondline-fanwars{
    margin-top: 0%;
    width: 27%;
}

@media screen and (max-width: 540px){
    .secondline-fanwars{
        margin-top: 0%;
        display: flex;
        justify-content: center;
        width: 100%;
        height: auto;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .secondline-fanwars{
        margin-top: 3%;
        display: flex;
        justify-content: center;
        width: 40%;
        height: auto;
    }
}

.forlike-image{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 50px;
    border-radius: 50%;
    margin-top: 2%;
}

.like-image{
    padding: 6px 7px;
}

.likeimage-txt{
    margin-top: 0px;
    text-align: center;
    font: normal normal normal 20px/25px Nobel-Regular;
    letter-spacing: 0.44px;
    color: #FFFFFF;
    opacity: 1;
}

.fordislike-image{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 50px;
    border-radius: 50%;
    margin-top: 2%;
}

.dislike-image{
    padding: 10px 7px;
}

.dislikeimage-txt{
    margin-top: 0px;
    text-align: center;
    font: normal normal normal 20px/25px Nobel-Regular;
    letter-spacing: 0.44px;
    color: #FFFFFF;
    opacity: 1;
}


/* .averagehr-txtsecondline{
    font: normal normal bold 24px/31px Nobel;
    letter-spacing: 0.53px;
    color: #FFFFFF;
} */

.my-fanemote-score{
    height: 20px;
    margin-top: 2%;
}

.myfanemote-image{
    height: 48px;
    width: 71px;
}

.fe-score{
    margin-top: 5px;
    height: 5px;
    text-align: center;
    font: normal normal bold 24px/17px Nobel;
    letter-spacing: 1.44px;
    color: #FFFFFF;
    opacity: 1;
}

.fescore-txt{
    margin-top: 0px;
    height: 10px;
    text-align: center;
    font: normal normal normal 14px/17px Nobel-Regular;
    letter-spacing: 0.28px;
    color: #FFFFFF;
    opacity: 1;
}

@media screen and (max-width: 540px){
    .fescore-txt{
        margin-top: 0px;
        height: 10px;
        text-align: center;
        font: normal normal normal 17px/5px Nobel-Regular;
        letter-spacing: 0.28px;
        color: #FFFFFF;
        opacity: 1;
    }
}

.index-thirdline{
    display: inline;
    /* margin-top: 3%; */
    margin-top: 0%;
}


@media screen and (max-width: 540px){
    .index-thirdline{
        margin-top: 5%;
    }
}

.thirdline-fanwarchart{
    /* height: 3vh; */
    height: 11vh;
}

@media screen and (max-width: 540px){
    .thirdline-fanwarchart{
        /* height: 1vh; */
        height: 12vh;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .thirdline-fanwarchart{
        /* height: 1.1vh; */
        height: 8vh;
    }
}

.index-fourthline{
    margin-top: 2%;
}

@media screen and (max-width: 540px){
    .index-fourthline{
        margin-top: 22%;
    }
   
}

@media (min-width: 740px) and (max-width: 940px){
    .index-fourthline{
        margin-top: 15%;
    }
}

.fanwar-charts{
    overflow-y: hidden;
}

.fanwar-leaderboard{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    flex-wrap: wrap;
    /* margin-top: 2%; */
    width: 100%;
    margin-bottom: 2%;
}

.for-chart{
    background: #274B9F 0% 0% no-repeat padding-box;
    /* background: rgba(2, 42, 150, 0.932); */
    border-radius: 16px;
    opacity: 0.8;
    height: auto;
    padding-bottom: 10px;
    width: 30%;
    /* margin-bottom: 30px; */
}

@media screen and (max-width: 540px){
    .for-chart{
        background: #274B9F 0% 0% no-repeat padding-box;
        /* background: rgba(2, 42, 150, 0.932); */
        border-radius: 16px;
        opacity: 0.8;
        height: 44vh;
        width: 80%;
    }
}

@media screen and (max-width: 540px) {
    .fanwar-charts{
        margin-top: 42%;
        display: flex;
        justify-content: center;
        justify-content: space-around;
    }
}


.chart-topsession{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    height: 37px;
    margin-top: 1%;
}

.teamoneonline-user{
    height: 30px;
}

.teamtwoonline-user{
    height: 30px;
}

.forteamoneimg{
    align-items: center;
    height: 35px;
    width: 35px;
}

.forteamtwoimg{
    padding-left: 0%;
    align-items: center;
    height: 35px;
    width: 35px;
}

.teamone-count{
    height: 2px;
    margin-top: 0px;
    text-align: center;
    font: normal normal normal 14px/17px Nobel-Regular;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.teamtwo-count{
    height: 2px;
    margin-top: 0px;
    text-align: center;
    font: normal normal normal 14px/17px Nobel-Regular;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}



.teamone-imagechart{
    margin-top: 2%;
}

.chartteamone-img{
    height: 38px;
    width: 38px;
}

.heart-imagechart{
    margin-top: 2%;
}

.heartchart-img{
    height: 150%;
    width: 110%;
}

.teamtwo-imagechart{
    margin-top: 2%;
}

.chartteamtwo-img{
    height: 38px;
    width: 38px;
}


/* .forheart-lottie{
    height: 30px;
    padding: 15px 5px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 60%;
    opacity: 1;
} */

.heartlottie{
    padding-top: 0px;
}

.heartwar-image{
    height: 50px;
    width: 50px;
    padding-left: 45%;
    padding-top: 1.5%;
}

@media screen and (max-width: 540px){
    .heartwar-image{
        height: 47px;
        width: 47px;
        padding-left: 44%;
        padding-top: 1.5%;
    }
}


.for-online{
    height: 10px;
}

.onlineuser-img{
    height: 35px;
    width: 35px;
    margin-top: 5px;
    margin-left: 60%;
}

@media screen and (max-width: 540px){
    .onlineuser-img{
        height: 35px;
        width: 35px;
        margin-top: 5px;
        margin-left: 50%;
    }
}

.onineuser-count{
    /* text-align: end; */
    padding-left: 90px;
    height: 2px;
    padding-top: 0px;
    margin-top: 25px;
    font: normal normal bold 16px/17px Nobel;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
}

@media screen and (max-width: 540px){
    .onineuser-count{
        /* text-align: end; */
        padding-left: 66px;
        height: 2px;
        padding-top: 0px;
        margin-top: 25px;
        font: normal normal bold 16px/17px Nobel;
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
    }
}


.fanwar-teams{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    height: 45px;
}

.fanwarteams-txt{
    text-align: center;
    font: normal normal normal 20px/25px Nobel-Regular;
    letter-spacing: 0.46px;
    color: #FFFFFF;
    opacity: 1;
}

@media screen and (max-width: 540px){
    .fanwarteams-txt{
        text-align: center;
        font: normal normal normal 20px/25px Nobel-Regular;
        letter-spacing: 0.46px;
        color: #FFFFFF;
        opacity: 1;
    }
}

.chart-view{
    height: 120%;
    font: normal normal normal 10px/15px Nolan;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.line-chart{
    font: normal normal normal 10px/15px Nolan;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

/* @media screen and (max-width: 540px){
    .chart-view{
        height: 150%;
    }
} */

.for-leaderboard{
    background: #274B9F 0% 0% no-repeat padding-box;
    /* background: rgba(2, 42, 150, 0.932); */
    border-radius: 16px;
    opacity: 0.8;
    height: auto;
    width: 30%;
    padding-bottom: 20px;
}

@media screen and (max-width: 540px){
    .for-leaderboard{
        background: #274B9F 0% 0% no-repeat padding-box;
        /* background: rgba(2, 42, 150, 0.932); */
        border-radius: 16px;
        opacity: 0.8;
        height: auto;
        width: 80%;
        margin-top: 30px;
        padding-bottom: 14px;
    }
    
}

.leaderboard-heading{
    text-align: center;
    font: normal normal normal 24px/6px Nobel-Regular;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    height: 0px;
}

.leaderboard-first{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    margin-left: 34%;
    background: rgba(0, 0, 0, 0.5) 0% 0% no-repeat padding-box;
    border-radius: 16px;
    opacity: 1;
    width: 30%;
    height: 133px;
}

.for-others{
    display: flex;
    justify-content: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 3%;
}

.for-othersdata{
    background: rgba(0, 0, 0, 0.5) 0% 0% no-repeat padding-box;
    border-radius: 16px;
    opacity: 1;
    width: 30%;
    height: 133px;
}


.leaderboard-imagefirst{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    margin-top: 12px;
    height: 42px;
}

.firstrank-image{
    height: 47px;
    width: 47px;
    background: transparent  0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 50px;
    border: 1px dashed #FFFFFF;
    opacity: 1;
}

.leaderboard-namefirst{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    height: 26px;
}

.firstrank-name{
    text-align: center;
    font: normal normal normal 16px/12px Nobel-Book;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.leaderboard-placefirst{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    height: 22px;
}

.firstrank-place{
    text-align: center;
    font: normal normal normal 14px/19px Nobel-Book;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.leaderboard-pointsfirst{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    height: 25px;
}

.points-image{
    height: 10px;
    width: 10px;
    margin-right: 2px;
}

.firstrank-points{
    text-align: center;
    font: normal normal normal 11px/15px Nobel-Book;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.leaderboardscnd-third{
    display: flex;
    justify-content: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 2%;
    margin-left: 3%;
    margin-right: 3%;
}


.for-second{
    background: rgba(0, 0, 0, 0.5) 0% 0% no-repeat padding-box;
    border-radius: 16px;
    opacity: 1;
    width: 30%;
    height: 133px;
}

.leaderboard-imagesecond{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    margin-top: 12px;
    height: 42px;
}

.secondrank-image{
    height: 47px;
    width: 47px;
    background: transparent  0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 50px;
    border: 1px dashed #FFFFFF;
    opacity: 1;
}

.leaderboard-namesecond{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    height: 26px;
}

.secondrank-name{
    text-align: center;
    font: normal normal normal 16px/10px Nobel-Book;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.leaderboard-placesecond{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    height: 22px;
}

.secondrank-place{
    text-align: center;
    font: normal normal normal 14px/19px Nobel-Book;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.leaderboard-pointssecond{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    height: 25px;
}

.points-image{
    height: 10px;
    width: 10px;
}

.secondrank-points{
    text-align: center;
    font: normal normal normal 11px/15px Nobel-Book;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}


.for-third{
    background: rgba(0, 0, 0, 0.5) 0% 0% no-repeat padding-box;
    border-radius: 16px;
    opacity: 1;
    width: 30%;
    height: 133px;
}

.leaderboardfrth-fifth{
    display: flex;
    justify-content: flex-start;
    justify-content: space-around;
    margin-top: 2%;
    margin-left: 3%;
    margin-right: 3%;
}

.for-fourth{
    background: rgba(0, 0, 0, 0.5) 0% 0% no-repeat padding-box;
    border-radius: 16px;
    opacity: 1;
    width: 30%;
    height: 133px;
}

.for-fifth{
    background: rgba(0, 0, 0, 0.5) 0% 0% no-repeat padding-box;
    border-radius: 16px;
    opacity: 1;
    width: 30%;
    height: 133px;
}

.for-quiz{
    background: #274B9F 0% 0% no-repeat padding-box;
    border-radius: 16px;
    opacity: 0.8;
    height: auto;
    width: 30%;
}

@media screen and (max-width: 540px){
    .for-quiz{
        margin-top: 7%;
        background: #274B9F 0% 0% no-repeat padding-box;
        border-radius: 16px;
        opacity: 0.8;
        height: auto;
        width: 80%;
    }
}

.quiz-heading{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    height: 60px;
}

.quizheading-txt{
    text-align: center;
    font: normal normal normal 30px/10px Nobel-Regular;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.quiz-question{
    height: 65px;
}

.quiz-questiontxt{
    text-align: center;
    font: normal normal normal 20px/20px Nobel-Regular;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.for-options{
    margin-top: 2%;
    display: flex;
    /* justify-content: center;
    justify-content: space-around; */
    margin-left: 10%;
    width: 80%;
    /* background: #FFFFFF 0% 0% no-repeat padding-box; */
    border: 1px solid #274B9F;
    border-radius: 24px;
    opacity: 1;
}

/* .foroptioncolor{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #274B9F;
    opacity: 1;
} */

.foroptions-txt{
    padding-left: 4%;
    text-align: left;
    font: normal normal normal 16px/15px Nobel-Regular;
    letter-spacing: 0px;
    color: #274B9F;
    opacity: 1;
}

.quiz-submitt{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    margin-top: 4%;
    padding-bottom: 15px;
}

@media screen and (max-width: 540px){
    .quiz-submitt{
        display: flex;
        justify-content: center;
        justify-content: space-around;
        margin-top: 4%;
        padding-bottom: 25px;
    }
}

.quizsubmit-image{
    height: 45px;
    width: 55%;
}

.forfanwars-entiresession{
    background: #274B9F 0% 0% no-repeat padding-box;
    border-radius: 16px;
    opacity: 0.8;
    height: auto;
    width: 30%;
}

@media screen and (max-width: 540px){
    .forfanwars-entiresession{
        background: #274B9F 0% 0% no-repeat padding-box;
        border-radius: 16px;
        opacity: 0.8;
        height: auto;
        width: 80%;
        padding-bottom: 4%;
    }
}

.fanwar-txt{
    /* display: flex;
    justify-content: center;
    justify-content: space-around; */
    height: 37px;
}

@media screen and (max-width: 540px){
    .fanwar-txt{
        display: flex;
        justify-content: center;
        justify-content: space-around;
        height: 35px;
    }
}

.fanwarhdng-team{
    position: absolute;
    padding-left: 15%;
    padding-top: 0px;
    text-align: center;
    font: normal normal bold 16px/25px Nobel;
    letter-spacing: 0px;
    color: #FFFFFF;
}

@media screen and (max-width: 540px){
    .fanwarhdng-team{
        position: absolute;
        padding-left:17%;
        padding-top: 18px;
        text-align: center;
        font: normal normal bold 16px/25px Nobel;
        letter-spacing: 0px;
        color: #FFFFFF;
    }
}

.fanwarhdng-txt{
    text-align: center;
    width: 70%;
    margin-top: 20px;
    text-align: end;
    font: normal normal bold 24px/31px Nobel;
    letter-spacing: 0.53px;
    color: #FFFFFF;
}

@media screen and (max-width: 540px){
    .fanwarhdng-txt{
        margin-left: 5%;
        width: 90%;
        margin-top: 20px;
        text-align: center;
        font: normal normal bold 24px/31px Nobel;
        letter-spacing: 0.53px;
        color: #FFFFFF;
    }
}

.waronline-users{
    display: flex;
    justify-content: center;
    /* justify-content: center; */
}

.warsonline-userleft{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
}

/* .imgonlineuser-image{} */

.onlineuser-img{
    padding-left: 0px;
    height: 30px;
    width: 30px;
}

.warsonline-userright{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
}

.tmselction-andcheering{
    display: flex;
    justify-content: center;
    justify-content: space-around;
}

.swipable-teams{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    width: 90%;
    height: 10vh;
}

/* .carousel-align{
    display: flex;
    justify-content: center;
} */

.forselect-teams{
    background: #274B9F 0% 0% no-repeat padding-box;
    border-radius: 16px;
    opacity: 0.8;
    margin-top: 7%;
    width: 60%;
    padding-top: 1%;
    height: 20vh;
    /* width: 50%;
    padding-top: 2%; */
    padding-top: 2%;
}

@media screen and (max-width: 540px){
    .forselect-teams{
        width: 95%;
        height: auto;
        padding-top: 1%;
        padding-bottom: 2%;
    }
}

/* .forallteam-align{
    display: flex;
    justify-content: center;
    justify-content: space-around;
}

@media screen and (max-width: 540px){
    .forallteam-align{
        display: flex;
        justify-content: center;
        justify-content: space-around;
    }
} */

.tapcount-cheering{
    height: 5px;
    margin-top: 2%;
    text-align: center;
    font: normal normal bold 16px/10px Nobel;
    color: #FFFFFF;
    opacity: 1;
}

@media screen and (max-width: 540px){
    .tapcount-cheering{
        height: 2px;
        margin-top: 2%;
        text-align: center;
        font: normal normal bold 16px/10px Nobel;
        color: #FFFFFF;
        opacity: 1;
    }
}

.tapcount-forcountry{
    text-align: center;
    height: 1px;
    margin-top: 3%;
    padding-bottom: 3px;
    padding-left: 0px;
    font: normal normal bold 20px/15px Nobel;
    /* letter-spacing: 1.44px; */
    color: #FFFFFF;
    opacity: 1;
}

@media screen and (max-width: 540px){
    .tapcount-forcountry{
        text-align: center;
        height: 1px;
        padding-top: 2%;
        padding-bottom: 0px;
        padding-left: 0%;
        font: normal normal bold 21px/10px Nobel;
        /* letter-spacing: 1.44px; */
        color: #FFFFFF;
        opacity: 1;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .tapcount-forcountry{
        text-align: center;
        height: 1px;
        padding-top: 2%;
        padding-bottom: 0px;
        padding-left: 0%;
        font: normal normal bold 24px/6px Nobel;
        /* letter-spacing: 1.44px; */
        color: #FFFFFF;
        opacity: 1;
    }
}

.tapcount-forcountryname{
    text-align: center;
    height: 1px;
    /* margin-top: 3%; */
    padding-bottom: 3px;
    padding-left: 0px;
    padding-right: 15px;
    font: normal normal normal 15px/10px Nobel-Regular;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

@media screen and (max-width: 540px){
    .tapcount-forcountryname{
        text-align: center;
        height: 15px;
        margin-top: 5%;
        padding-bottom: 7%;
        padding-left: 0px;
        padding-right: 8px;
        font: normal normal normal 14.5px/10px Nobel-Regular;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .tapcount-forcountryname{
        text-align: left;
        height: 1px;
        margin-top: 15%;
        padding-bottom: 3%;
        /* padding-right: 15%; */
        padding-left: 5px;
        font: normal normal normal 19px/10px Nobel-Regular;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
    }
}

.inida-image{
    padding-left: 0%;
    padding-top: 25%;
    padding-bottom: 20%;
    /* display: flex;
    justify-content: center;
    justify-content: space-around; */
    height: 50px;
    width: 50px;
    border-radius: 50px;
    /* border: 1px dashed ; */
    padding: 0px 0px;
}

@media screen and (max-width: 540px){
    .inida-image{
        /* border-radius: 70px; */
        margin-left: 0%;
        padding-left: 0%;
        margin-top: 25%;
        margin-bottom: 20%;
        height: 50px;
        width: 50px;
        border-radius: 50px;
        /* border: 1px dashed ; */
        padding: 0px 0px;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .inida-image{
        display: flex;
        justify-content: center;
        justify-content: space-around;
        margin-left: 3%;
        padding-top: 1%;
        height: 50px;
        width: 50px;
        border-radius: 50px;
        /* border: 1px dashed ; */
        padding: 0px 0px;
    }
}

.austalia-image{
    padding-left: 40%;
}

.footerball-images{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-right: 5%;
    /* height: 40px; */
}

/* @media screen and (max-width: 540px){
    .footerball-images{
       z-index: 5;
    }
} */

.footerdownload-images{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    padding-bottom: 15px;
    margin-top: 14%;
}

@media screen and (max-width: 540px){
    .footerdownload-images{
        z-index: 5;
        margin-top: 3%;
        padding-bottom: 15px;
        
    }
}

@media screen and (max-width: 540px){
    .footerdownload-playstore{
        z-index: 5;
    }
}


.scorecard-entiredic{
    overflow-y: hidden;
    margin-top: 0%;
    padding-top: 0%;
    /* height: 30vh; */
    /* background-color: #FFFFFF; */
}

.card-div{
    padding-top: 0%;
    margin-top: 0%;
    width: 100%;
    height: 19vh;
}

@media screen and (max-width: 540px){
    .card-div{
        padding-top: 5%;
        width: 100%;
        height: 20vh;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .card-div{
        padding-top: 1.5%;
        width: 100%;
        height: 20vh;
    }
}

.card-styling{
    background-size: cover;
    width: 100%;
    margin-top: 0%;
}

.card .cardsession-forscore{
    margin-top: 0%;
    padding-top: 1%;
    outline: none;
    border: none;
    width: 100%;
    height: 7vh;
}

@media (min-width: 740px) and (max-width: 940px){
    .card .cardsession-forscore{
        margin-top: 0%;
        padding-top: 1%;
        outline: none;
        border: none;
        width: 100%;
        height: 5vh;
    }
}

.cardsession-forscore{
    display: flex;
    justify-content: flex-start;
    justify-content: space-between;
    padding-top: 12%;
}

.firstteam-name{
    width: 25%;
    height: 8.5vh;
    /* background: linear-gradient(#eff1f0, #c1c2c4,#a1a4a7); */
    /* background: #FFFFFF 0% 0% no-repeat padding-box; */
    /* border-top-right-radius: 50px;
    border-bottom-right-radius: 50px; */
    background: transparent linear-gradient(180deg, #2363EB 0%, #102553 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #3473C0;
    opacity: 1;
    outline: none;
}

@media screen and (max-width: 540px){
    .firstteam-name{
        /* display: flex;
        flex-direction: column-reverse;
        justify-content: center; */
        width: 25%;
        height: 7.8vh;
        /* background: linear-gradient(#eff1f0, #c1c2c4,#a1a4a7); */
        /* border-top-right-radius: 50px;
        border-bottom-right-radius: 50px; */
        /* background: #FFFFFF 0% 0% no-repeat padding-box; */
        background: transparent linear-gradient(180deg, #2363EB 0%, #102553 100%) 0% 0% no-repeat padding-box;
        /* border-radius: 0px 24px 24px 0px; */
        border: 1px solid #3473C0;
        opacity: 1;
        outline: none;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .firstteam-name{
        /* display: flex;
        flex-direction: column-reverse;
        justify-content: center; */
        width: 25%;
        height: 5.7vh;
        /* background: linear-gradient(#eff1f0, #c1c2c4,#a1a4a7); */
        /* border-top-right-radius: 50px;
        border-bottom-right-radius: 50px; */
        /* background: #FFFFFF 0% 0% no-repeat padding-box; */
        background: transparent linear-gradient(180deg, #2363EB 0%, #102553 100%) 0% 0% no-repeat padding-box;
        /* border-radius: 0px 24px 24px 0px; */
        border: 1px solid #3473C0;
        opacity: 1;
        outline: none;
    }
}

.teamname-txt{
    padding-top: 1%;
    text-align: end;
    padding-right: 15px;
    font: normal normal bold 24px/20px Nobel;
    letter-spacing: 0px;
    color: #284BA0;
    text-transform: uppercase;
    opacity: 1;
}


@media screen and (max-width: 540px){
    .teamname-txt{
        /* display: flex;
        flex-direction: column;
        justify-content: center; */
        /* padding-top: 40%; */
        text-align: end;
        padding-right: 10px;
        padding-left: 2px;
        /* white-space: nowrap; */
        text-overflow: ellipsis;
        font: normal normal bold 7.5px/10px Nobel;
        letter-spacing: 0px;
        color: #284BA0;
        text-transform: uppercase;
        opacity: 1;
    }
}

.scoreboard-mid{
    width: 699px;
    height: 8.8vh;
    /* background: linear-gradient(#eff1f0, #c1c2c4,#a1a4a7); */
    /* background: #FFFFFF 0% 0% no-repeat padding-box; */
    background: #274B9F 0% 0% no-repeat padding-box;
    /* border: 1px solid #3473C0; */
    opacity: 1;
    /* border-radius: 50px; */
    outline: none;
    /* display: flex;
    justify-content: flex-start;
    justify-content: space-between; */
}

@media screen and (max-width: 540px){
    .scoreboard-mid{
        /* z-index: 5; */
        width: 50%;
        height: 8vh;
        /* background: linear-gradient(#eff1f0, #c1c2c4,#a1a4a7); */
        /* background: #FFFFFF 0% 0% no-repeat padding-box; */
        background: #274B9F 0% 0% no-repeat padding-box;
        /* border: 1px solid #3473C0; */
        opacity: 1;
        /* border-radius: 50px; */
        outline: none;
        /* display: flex;
        justify-content: flex-start;
        justify-content: space-between; */
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .scoreboard-mid{
        /* z-index: 5; */
        width: 50%;
        height: 5.9vh;
        /* background: linear-gradient(#eff1f0, #c1c2c4,#a1a4a7); */
        /* background: #FFFFFF 0% 0% no-repeat padding-box; */
        background: #274B9F 0% 0% no-repeat padding-box;
        /* border: 1px solid #3473C0; */
        opacity: 1;
        /* border-radius: 50px; */
        outline: none;
        /* display: flex;
        justify-content: flex-start;
        justify-content: space-between; */
    }
}

.forstrickerdetail{
    height: 2vh;
    display: flex;
    justify-content: flex-start;
    color: #ffffff;
    margin-top: 0.5vh;
}

@media screen and (max-width: 540px){
    .forstrickerdetail{
        height: 3vh;
        display: flex;
        justify-content: flex-start;
        color: #ffffff;
        margin-top: 0.5vh;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .forstrickerdetail{
        height: 2vh;
        margin-top: 0.5vh;
        display: flex;
        justify-content: flex-start;
        color: #ffffff;
    }
}

.fornonstrickerdetail{
    height: 4vh;
    display: flex;
    justify-content: flex-end;
    /* color: #ffffff; */
    padding-top: 0%;
    margin-right: 2%;
}

@media screen and (max-width: 540px){
    .fornonstrickerdetail{
        height: 4vh;
        display: flex;
        justify-content: flex-end;
        /* color: #ffffff; */
        padding-top: 0%;
        margin-right: 2%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .fornonstrickerdetail{
        height: 3vh;
        display: flex;
        justify-content: flex-end;
        /* color: #ffffff; */
        padding-top: 0%;
        margin-right: 2%;
    }
}

.forstrickerplayer{
        /* white-space: nowrap; */
        display: flex;
        align-items: stretch;
        text-overflow: ellipsis;
        color: #ffffff;
        font-size: 11px;
        font-weight: 500;
        margin-top: 0px;
        padding-top: 5px;
        padding-left: 0px;
        height: 35px;
}

.fornonstrickerplayer{
     /* white-space: nowrap; */
     display: flex;
    align-items: stretch;
     text-overflow: ellipsis;
     color: #fdfdfd;
     font-size: 11px;
     padding-top: 0%;
     font-weight: 500;
     text-align: center;
     padding-left: 0px;
     padding-bottom: 2%;
     height:35px;
}

.playersnames-scorecard{
    width: 50%;
    display: flex;
    justify-content: center;
}

.forplayesname{
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    color: #ffffff;
    margin-top: 5%;
}

.sticker-name{
    color: #ffffff;
    display: inline;
    font-size: 16px;
    font-weight: 600;
    /* text-align: center; */
    margin-top: 0px;
    padding-top: 0px;
    height: 15px;
}

@media screen and (max-width: 540px){
    .sticker-name{
        /* white-space: nowrap; */
        display: flex;
        align-items: stretch;
        text-overflow: ellipsis;
        color: #ffffff;
        font-size: 11px;
        font-weight: 500;
        margin-top: 0%;
        padding-top: 0.8vh;
        padding-left: 0px;
        /* height: 35px; */
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .sticker-name{
        /* white-space: nowrap; */
        display: flex;
        align-items: stretch;
        text-overflow: ellipsis;
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
        margin-top: 0%;
        padding-top: 0vh;
        padding-left: 0px;
        /* height: 35px; */
    }
}

.nonsticker-name{
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    padding-left: 20px;
    padding-bottom: 10px;
    height: 15px;
}

@media screen and (max-width: 540px){
    .nonsticker-name{
        /* white-space: nowrap; */
        /* display: flex;
        align-items: stretch;
        text-overflow: ellipsis;
        color: #ffffff;
        font-size: 11px;
        font-weight: 500;
        padding-bottom: 0.8vh; */

        color: #fcfcfc;
        font-size: 11px;
        padding-bottom: 3vh;
        font-weight: 600;
        text-align: center;
        padding-bottom: 7px;
        padding-top: 0%;
        height: 15px;
        
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .nonsticker-name{

        color: #fcfcfc;
        font-size: 16px;
        padding-bottom: 7vh;
        font-weight: 600;
        text-align: center;
        padding-bottom: 7px;
        padding-top: 0%;
        height: 15px;
        
    }
}

.stickerrun-balls{
    color: #ffffff;
    font-size: 13px;
    font-weight: 600;
    /* text-align: center; */
    margin-top: 0px;
    padding-top: 4px;
    height: 15px;
}

@media screen and (max-width: 540px){
    .stickerrun-balls{
        color: #ffffff;
        font-size: 10px;
        font-weight: 600;
        /* text-align: center; */
        margin-top: 0px;
        padding-top: 0.8vh;
        /* height: 15px; */
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .stickerrun-balls{
        color: #ffffff;
        font-size: 13px;
        font-weight: 600;
        /* text-align: center; */
        margin-top: 0px;
        padding-top: 0.3vh;
        /* height: 15px; */
    }
}

.nonstickerrun-ball{
    color: #ffffff;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    padding-top: 0.8vh;
    padding-bottom: 0px;
    height: 15px;
}

@media screen and (max-width: 540px){
    .nonstickerrun-ball{
        color: #fcfcfc;
        font-size: 9px;
        font-weight: 600;
        text-align: center;
        padding-bottom: 0px;
        padding-top: 2%;
        height: 15px;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .nonstickerrun-ball{
        color: #fcfcfc;
        font-size: 13px;
        font-weight: 600;
        text-align: center;
        padding-bottom: 0px;
        padding-top: 1%;
        height: 15px;
    }
}

.innings-runs{
    background: #1e216f;
    width: 37%;
    border-radius: 50px;
    height: 64px;
    margin: 2px 0px 3px 2px ;
}

@media screen and (max-width: 540px){
    .innings-runs{
        padding: 1px 0px 1px 2px ;
    }
    
}

.batting-team{
    font-weight: bold;
    color: #fff;
    font-size: 18px;
    text-align: center;
    margin-top: 0%;
    padding-top: 0%;
     height: 20%;
}

@media screen and (max-width: 540px){
    .batting-team{
        text-align: center;
        text-overflow: ellipsis;
        height: 35%;
        font-size: 10px;
        margin-top: 0%;
        padding-top: 5%;
        letter-spacing: 0px;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .batting-team{
        text-align: center;
        text-overflow: ellipsis;
        font-size: 16px;
        height: 20%;
        margin-top: 0%;
        padding-top: 5%;
        letter-spacing: 0px;
    }
}

.scoresof-batting{
    color: #fff;
    font-size: 18px;
    text-align: center;
    padding-top: 0%;
    padding-bottom: 2%;
    margin-top: 0%;
    /* font-size: 22px;
    background: linear-gradient(to right, rgba(133,29,82,.0) , #e31857);
    padding: 2px 10px 0px 40px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px; */
    /* margin-left: -28px; */
    font-weight: bold;
}

@media screen and (max-width: 540px){
    .scoresof-batting{
        color: #fff;
        font-size: 10px;
        text-align: center;
        padding-top: 0%;
        padding-bottom: 5%;
        margin-top: 0%;
        /* background: linear-gradient(to right, rgba(133,29,82,.0) , #e31857);
        padding: 0px 10px 0px 30px;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        margin-left: -28px; */
        font-weight: bold;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .scoresof-batting{
        color: #fff;
        font-size: 15px;
        text-align: center;
        padding-top: 0%;
        padding-bottom: 8%;
        margin-top: 0%;
        /* background: linear-gradient(to right, rgba(133,29,82,.0) , #e31857);
        padding: 0px 10px 0px 30px;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px; */
        /* margin-left: -28px; */
        font-weight: bold;
    }
}


.forscoewe-session{
    margin-top: 0px;
    padding: 18px 0px 0px 0px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
}

@media screen and (max-width: 540px){
    .forscoewe-session{
        /* white-space: nowrap; */
        text-overflow: ellipsis;
        padding: 0.5px 0px 0px 0px;
        text-align: center;
        font-size: 17x;
        font-weight: 600;
        color: #ffffff;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .forscoewe-session{
        /* white-space: nowrap; */
        text-overflow: ellipsis;
        padding: 15px 0px 0px 0px;
        text-align: center;
        font-size: 17x;
        font-weight: 600;
        color: #ffffff;
    }
}

.forscoewes-session{
    margin-top: 0px;
    padding: 18px 0px 0px 0px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #85a5a2;
}

@media screen and (max-width: 540px){
    .forscoewes-session{
        /* white-space: nowrap; */
        text-overflow: ellipsis;
        padding: 10px 0px 0px 0px;
        text-align: center;
        font-size: 18x;
        font-weight: 600;
        color: #85a5a2;
    }

}


.scorebord-right{
    width: 25%;
    height: 8.5vh;
    /* background: linear-gradient(#eff1f0, #c1c2c4,#a1a4a7); */
    /* background: #FFFFFF 0% 0% no-repeat padding-box; */
    /* border-top-right-radius: 50px;
    border-bottom-right-radius: 50px; */
    background: transparent linear-gradient(180deg, #2363EB 0%, #102553 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #3473C0;
    opacity: 1;
    outline: none;
}

@media screen and (max-width: 540px){
    .scorebord-right{
        /* display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        width: 12%;
        height: 70px; */
        /* background: linear-gradient(#eff1f0, #c1c2c4,#a1a4a7); */
        /* background: #FFFFFF 0% 0% no-repeat padding-box;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        border: 1px solid #3473C0;
        opacity: 1;
        outline: none; */
        width: 25%;
        height: 7.8vh;
        background: transparent linear-gradient(180deg, #2363EB 0%, #102553 100%) 0% 0% no-repeat padding-box;
        border: 1px solid #3473C0;
        opacity: 1;
        outline: none;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .scorebord-right{
        /* display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        width: 12%;
        height: 70px; */
        /* background: linear-gradient(#eff1f0, #c1c2c4,#a1a4a7); */
        /* background: #FFFFFF 0% 0% no-repeat padding-box;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        border: 1px solid #3473C0;
        opacity: 1;
        outline: none; */
        width: 25%;
        height: 5.7vh;
        background: transparent linear-gradient(180deg, #2363EB 0%, #102553 100%) 0% 0% no-repeat padding-box;
        border: 1px solid #3473C0;
        opacity: 1;
        outline: none;
    }
}

.current-bowler{
    width: 33%;
}

.bowler-name{
    color: #000000;
    font-size: 13px;
    font-weight: 600;
    text-align: end;
    margin-top: 0px;
    padding-top: 1.8%;
    padding-right: 2%;
    height: 20px;
}

@media screen and (max-width: 540px){
    .bowler-name{
        /* white-space: nowrap; */
        text-overflow: ellipsis;
        color: #000000;
        font-size: 9.5px;
        font-weight: 600;
        text-align: end;
        padding-top: 10px;
        padding-right: 2%;
        height: 20px;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .bowler-name{
        /* white-space: nowrap; */
        text-overflow: ellipsis;
        color: #000000;
        font-size: 14px;
        font-weight: 600;
        text-align: end;
        padding-top: 1.7%;
        padding-right: 2.5%;
        height: 20px;
    }
}

.required-runs{
    color: #000000;
    font-size: 13px;
    font-weight: 600;
    padding-bottom: 4%;
    padding-left: 2%;
    padding-top: 0%;
    text-align: start;
    height: 20px;
}

@media screen and (max-width: 540px){
    .required-runs{
        /* white-space: nowrap; */
        text-overflow: ellipsis;
        color: #000000;
        font-size: 9.5px;
        font-weight: 600;
        padding-bottom: 2px;
        padding-left: 2%;
        text-align: start;
        height: 20px;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .required-runs{
        /* white-space: nowrap; */
        text-overflow: ellipsis;
        color: #000000;
        margin-top: 1.7%;
        font-size: 15px;
        font-weight: 600;
        padding-bottom: 0px;
        padding-left: 2.5%;
        text-align: start;
        height: 20px;
    }
}

.scndteamname-txt{
    padding-top: 1%;
    text-align: start;
    padding-left: 15px;
    font: normal normal bold 24px/20px Nobel;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
}

@media screen and (max-width: 540px){
    .scndteamname-txt{
        /* white-space: nowrap; */
        padding-top: 5%;
        text-align: start;
        /* padding-right: 10px; */
        /* padding-left: 2px; */
        text-overflow: ellipsis;
        font: normal normal bold 10px/15px Nobel;
        letter-spacing: 0px;
        color: #ffffff;
        text-transform: uppercase;
        opacity: 1;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .scndteamname-txt{
        /* white-space: nowrap; */
        padding-top: 0%;
        text-align: center;
        padding-right: 10px;
        padding-left: 2px;
        text-overflow: ellipsis;
        font: normal normal bold 15px/15px Nobel;
        letter-spacing: 0px;
        color: #ffffff;
        text-transform: uppercase;
        opacity: 1;
    }
}

.bottom-card{
    display: flex;
    justify-content: flex-start;
    margin-top: 0%;
    height: 4.5vh;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
}

@media screen and (max-width: 540px){
    .bottom-card{
        display: flex;
        justify-content: flex-start;
        margin-top: 0%;
        height: 3.3vh;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        opacity: 1;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .bottom-card{
        display: flex;
        justify-content: flex-start;
        margin-top: 0%;
        height: 2.5vh;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        opacity: 1;
    }
}

.forrequiredruns-bottom{
    width: 50%;
}


.bollername-bottom{
    width: 50%;
}

.foruserleaderboard{
    display: flex;
    justify-content: center;
    justify-content: space-around;
}

@media screen and (max-width: 540px){
    .foruserleaderboard{
        display: flex;
        justify-content: center;
        justify-content: space-around;
        width: 100%;
        /* margin-left: 15%; */
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .foruserleaderboard{
        display: flex;
        justify-content: center;
        justify-content: space-around;
        width: 95%;
        /* margin-left: 15%; */
    }
}

.entire-usersleaderboard{
    /* background: #274B9F 0% 0% no-repeat padding-box; */
    /* background: rgba(255, 255, 255, 0.5) 0% 0% no-repeat padding-box; */
    background: transparent linear-gradient(0deg, rgba(63, 94, 165, 1) 0%, rgba(7, 83, 234, 1) 100%) 0% 0% no-repeat padding-box;    border-radius: 16px;
        /* opacity: 0.75; */
    width: 100%;
    height: auto;
    padding-bottom: 5%;
    /* margin-bottom: 3%; */
}

@media screen and (max-width: 540px){
    .entire-usersleaderboard{
        z-index: 5;
        margin-right: 0%;
        margin-top: 0%;
        /* background: #274B9F 0% 0% no-repeat padding-box; */
        /* background: rgba(255, 255, 255, 0.5) 0% 0% no-repeat padding-box; */
        background: transparent linear-gradient(0deg, rgba(63, 94, 165, 1) 0%, rgba(7, 83, 234, 1) 100%) 0% 0% no-repeat padding-box;        /* background: #FFFFFF 0% 0% no-repeat padding-box; */
        border-radius: 16px;
        /* opacity: 0.65; */
        width: 95%;
        height: auto;
        margin-top: 3%;
        padding-bottom: 5%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .entire-usersleaderboard{
        z-index: 5;
        margin-right: 0%;
        margin-top: 0%;
        /* background: #274B9F 0% 0% no-repeat padding-box; */
        /* background: rgba(255, 255, 255, 0.5) 0% 0% no-repeat padding-box; */
        background: transparent linear-gradient(0deg, rgba(63, 94, 165, 1) 0%, rgba(7, 83, 234, 1) 100%) 0% 0% no-repeat padding-box;        /* background: #FFFFFF 0% 0% no-repeat padding-box; */
        /* opacity: 0.75; */
        width: 100%;
        height: auto;
        padding-bottom: 5%;
        margin-bottom: 2%;
    }
}

.userleaderboard-eachusers{
    background: transparent linear-gradient(0deg, #E5E5E5 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 1;
    height: auto;
    margin-top: 4%;
    margin-left: 6%;
    margin-right: 6%;
}

.usersranklist-item-leaderboard{
    display: flex;
    justify-content: flex-start;
    margin-top: 2%;
    margin-bottom: 2%;
    padding-right: 5%;
    opacity: 1;
}

.userimages-teamrank{
    height: 43px;
    width: 43px;
    margin-top: 0.3%;
    border-radius: 50%;
    margin-left: -1.3%;
}

.userrank-leaderboard{
    text-overflow: ellipsis;
    text-align: left;
    /* font: normal normal normal 14.5px/10px Nobel;
    letter-spacing: 0px;
    color: #3f3d3d; */
    font-weight: 600;
    font: normal normal normal 14.6px/11px Nobel-Regular;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    width: 8%;
    margin-left: 2%;
    padding: 2px 0px;
}

.userrank-usernameleaderboard{
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    font: normal normal normal 14.6px/11px Nobel-Regular;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    width: 60%;
}

.userrank-totalcheerleaderboard{
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    font: normal normal normal 14.6px/11px Nobel-Regular;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    width: 25%;
}

.forleaderboard-heading{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    margin-top: 2%;
}

.forleaderboard-headingtxt{
    text-align: center;
    font: normal normal bold 20px/5px Nobel;
    letter-spacing: 1.44px;
    /* color: #FFFFFF; */
    color: #FFFFFF;
}

.teamranks{
    display: flex;
    justify-content: center;
    justify-content: space-around;
}

@media screen and (max-width: 540px){
    .teamranks{
        display: flex;
        justify-content: center;
        justify-content: space-around;
        width: 100%;
        /* margin-left: 15%; */
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .teamranks{
        display: flex;
        justify-content: center;
        justify-content: space-around;
        width: 95%;
        /* margin-left: 15%; */
    }
}

.entire-teamrank{
    /* background: #274B9F 0% 0% no-repeat padding-box; */
    background: rgba(255, 255, 255, 0.5) 0% 0% no-repeat padding-box;
    border-radius: 16px;
        /* opacity: 0.75; */
    width: 100%;
    height: auto;
    padding-bottom: 3%;
    /* margin-bottom: 3%; */
}

@media screen and (max-width: 540px){
    .entire-teamrank{
        z-index: 5;
        margin-right: 0%;
        margin-top: 0%;
        /* background: #274B9F 0% 0% no-repeat padding-box; */
        background: rgba(255, 255, 255, 0.5) 0% 0% no-repeat padding-box;
        /* background: #FFFFFF 0% 0% no-repeat padding-box; */
        border-radius: 16px;
        /* opacity: 0.65; */
        width: 95%;
        height: auto;
        margin-top: 3%;
        padding-bottom: 3%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .entire-teamrank{
        z-index: 5;
        margin-right: 0%;
        margin-top: 0%;
        /* background: #274B9F 0% 0% no-repeat padding-box; */
        background: rgba(255, 255, 255, 0.5) 0% 0% no-repeat padding-box;
        border-radius: 16px;
        /* opacity: 0.75; */
        width: 100%;
        height: auto;
        padding-bottom: 3%;
        margin-bottom: 2%;
    }
}

.teamrank-onlinimg{
    margin-right: 6.1%;
}

.teamrank-soundimg{
    margin-right: 5.85%;
}

.teamrank-hrimg{
    margin-right: 5.85%;
}

.teamimages-teamrank{
    height: 37px;
    width: 37px;
    border-radius: 50%;
    padding-left: 0%;
}

.teamrank-images{
    display: flex;
    justify-content: flex-end;
    margin-top: 1%;
    margin-bottom: 0%;
    margin-right: 5%;
}

.teamranklist-colors{
    /* background: transparent linear-gradient(0deg, #E5E5E5 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box; */
    /* background: transparent linear-gradient(0deg, #E5E5E5 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box; */
    border-radius: 18px;
    opacity: 1;
    height: auto;
    margin-top: 4%;
    margin-left: 3%;
    margin-right: 3%;
}

.teamrank-list{
    display: flex;
    justify-content: flex-start;
    /* background: transparent linear-gradient(0deg, #E5E5E5 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    border-radius: 16px;
    height: 4vh; */
    /* padding-top: 2%;
    padding-right: 2%;
    padding-left: 2%; */
    margin-top: 2%;
    margin-bottom: 2%;
    padding-right: 5%;
    opacity: 1;
}

.teamrank-teamname{
    text-overflow: ellipsis;
    text-align: left;
    font: normal normal normal 16px/0px Nobel;
    letter-spacing: 0px;
    /* color: #191919; */
    opacity: 1;
    width: 45%;
    margin-left: 1.5%;
    padding: 2px 0px;
}

/* .hrandsoundsort{
    display: flex;
    justify-content: flex-end;
    width: 30%;
}

.teamrank-hr{
    text-align: center;
    font: normal normal normal 16px/5px Nobel;
    letter-spacing: 0px;
    opacity: 1;
    width: 15%;
}

.teamrank-sound{
    text-align: center;
    font: normal normal normal 16px/5px Nobel;
    letter-spacing: 0px;
    opacity: 1;
    width: 15%;
} */

.teamrank-onlineusers{
    text-align: center;
    font: normal normal normal 16px/5px Nobel;
    letter-spacing: 0px;
    /* color: #191919; */
    opacity: 1;
    width: 15%;
}


.totalteam-wars{
    display: flex;
    justify-content: center;
    justify-content: space-around;
}


@media screen and (max-width: 540px){
    .totalteam-wars{
        display: flex;
        justify-content: center;
        justify-content: space-around;
        width: 100%;
        /* margin-left: 15%; */
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .totalteam-wars{
        display: flex;
        justify-content: center;
        justify-content: space-around;
        width: 95%;
        /* margin-left: 15%; */
    }
}

.entireteam-war{
    background: #274B9F 0% 0% no-repeat padding-box;
    border-radius: 16px;
    opacity: 0.75;
    width: 100%;
    height: 70vh;
}

@media screen and (max-width: 540px){
    .entireteam-war{
        z-index: 5;
        margin-right: 0%;
        margin-top: 0%;
        background: #274B9F 0% 0% no-repeat padding-box;
        border-radius: 16px;
        opacity: 0.75;
        width: 87%;
        height: auto;
        padding-bottom: 3%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .entireteam-war{
        z-index: 5;
        margin-right: 0%;
        margin-top: 0%;
        background: #274B9F 0% 0% no-repeat padding-box;
        border-radius: 16px;
        opacity: 0.75;
        width: 100%;
        height: 42vh;
        padding-bottom: 3%;
    }
}

.apifanwar-txt{
    display: flex;
    justify-content: center;
    justify-content: space-around;
}

.forteamoneimg{
    padding-top: 2%;
}

.forteamtwoimg{
    padding-top: 2%;
}

.teamwar-hdg{
    text-align: center;
}

.teamwar-hdngtxt{
    text-align: center;
    font: normal normal bold 20px/8px Nobel;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.css-7p5u54{
    background: transparent linear-gradient(90deg, #F2F2F2 0%, #f1f1f1 100%) 0% 0% no-repeat padding-box;
}

.css-eglki6-MuiLinearProgress-root{
    background: transparent linear-gradient(90deg, #F2F2F2 0%, #f1f1f1 100%) 0% 0% no-repeat padding-box;
}

.css-t752vm{
    background: transparent linear-gradient(90deg, #74C64E 0%, #A0E252 19%, #F2F21C 40%, #F2A60F 60%, #F26E0A 80%, #F22D06 100%) 0% 0% no-repeat padding-box;
}

.css-5xe99f-MuiLinearProgress-bar1{
    background: transparent linear-gradient(90deg, #74C64E 0%, #A0E252 19%, #F2F21C 40%, #F2A60F 60%, #F26E0A 80%, #F22D06 100%) 0% 0% no-repeat padding-box;
}

/* .css-t752vm{
    background: transparent linear-gradient(90deg, #74C64E 0%, #A0E252 19%, #F2F21C 40%, #F2A60F 60%, #F26E0A 80%, #F22D06 100%) 0% 0% no-repeat padding-box;
} */

/* .css-5xe99f-MuiLinearProgress-bar1{
    background: transparent linear-gradient(90deg, #F22D06 0%, #F26E0A 19%, #F2A60F 40%, #F2F21C 60%, #A0E252 80%, #74C64E 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(90deg, #74C64E 0%, #A0E252 19%, #F2F21C 40%, #F2A60F 60%, #F26E0A 80%, #F22D06 100%) 0% 0% no-repeat padding-box;
} */

.progress-barleft {
   transform: rotate(180deg);
   border-radius: 0px 8px 8px 0px;
}

.progress-barleft .css-7p5u54{
    background: transparent linear-gradient(50deg, #74C64E 0%, #A0E252 19%, #F2F21C 40%, #F2A60F 60%, #F26E0A 80%, #F22D06 100%) 0% 0% no-repeat padding-box;
}

.progress-barleft .css-5xe99f-MuiLinearProgress-bar1{
    background: transparent linear-gradient(50deg, #74C64E 0%, #A0E252 19%, #F2F21C 40%, #F2A60F 60%, #F26E0A 80%, #F22D06 100%) 0% 0% no-repeat padding-box;
}

.progress-bar{
    border-radius: 0px 8px 8px 0px;
}

.progress-bar .css-7p5u54{
    background: transparent linear-gradient(90deg, #74C64E 0%, #A0E252 19%, #F2F21C 40%, #F2A60F 60%, #F26E0A 80%, #F22D06 100%) 0% 0% no-repeat padding-box;
}

.progress-bar .css-5xe99f-MuiLinearProgress-bar1{
    background: transparent linear-gradient(90deg, #74C64E 0%, #A0E252 19%, #F2F21C 40%, #F2A60F 60%, #F26E0A 80%, #F22D06 100%) 0% 0% no-repeat padding-box;
}

.teamwar-onlineuser{
    display: flex;
    justify-content: center;
    height: 6vh;
    margin-top: 1%;
}
@media screen and (max-width: 540px){
    .teamwar-onlineuser{
        height: 5vh;
        margin-top: 2%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .teamwar-onlineuser{
        height: 3.7vh;
        margin-top: 2.5%;
    }
}

.tmwaronlineuser-left{
    height: 15px;
}

.tmwaronline-userimg{
    margin-top: 30%;
    height: 35px;
    width: 35px;
}

.tmonlineuserone-count{
    margin-top: 0px;
    height: 5px;
    font: normal normal bold 16px/17px Nobel;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
}

.tmwaronlineuser-right{
    height: 15px;
}

.tmonlineusertwo-count{
    text-align: end;
    margin-top: 0px;
    height: 5px;
    font: normal normal bold 16px/17px Nobel;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
}

.tmwaronlineuser-hdng{
    height: 5px;
    padding-top: 0px;
}

.tmwaronlineuser-txt{
    text-align: center;
    font: normal normal normal 16px/0px Nobel-Regular;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.teamwar-heartbeat{
    display: flex;
    justify-content: center;
    height: 6vh;
    margin-top: 5%;
}

@media screen and (max-width: 540px){
    .teamwar-heartbeat{
        height: 5vh;
        margin-top: 7%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .teamwar-heartbeat{
        height: 3.7vh;
        margin-top: 7%;
    }
}

.tmwarheartbeat-left{
    height: 15px;
}

.tmwarheart-beatimg{
    margin-top: 30%;
    height: 35px;
    width: 35px;
}

.tmheartbeatone-count{
    margin-top: 0px;
    height: 5px;
    font: normal normal bold 16px/17px Nobel;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
}

.tmwarheartbeat-right{
    height: 15px;
}

.tmheartbeattwo-count{
    text-align: end;
    margin-top: 0px;
    height: 5px;
    font: normal normal bold 16px/17px Nobel;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
}

.tmwarheartbeat-hdng{
    height: 5px;
    margin-top: 0px;
}

.tmwarheartbeat-txt{
    text-align: center;
    font: normal normal normal 16px/0px Nobel-Regular;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.teamwar-tap{
    display: flex;
    justify-content: center;
    height: 6vh;
    margin-top: 5%;
}

@media screen and (max-width: 540px){
    .teamwar-tap{
        height: 5vh;
        margin-top: 7%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .teamwar-tap{
        height: 3.7vh;
        margin-top: 7%;
    }
}

.tmwartap-left{
    height: 15px;
}

.tmwartap-beatimg{
    margin-top: 30%;
    height: 35px;
    width: 35px;
}

.tmtapone-count{
    margin-top: 0px;
    height: 5px;
    font: normal normal bold 16px/17px Nobel;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
}

.tmwartap-right{
    height: 15px;
}

.tmtaptwo-count{
    text-align: end;
    margin-top: 0px;
    height: 5px;
    font: normal normal bold 16px/17px Nobel;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
}

.tmwartap-hdng{
    height: 5px;
    margin-top: 0px;
}

.tmwartap-txt{
    text-align: center;
    font: normal normal normal 16px/0px Nobel-Regular;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.teamwar-fanemote{
    display: flex;
    justify-content: center;
    height: 6vh;
    margin-top: 5%;
}

@media screen and (max-width: 540px){
    .teamwar-fanemote{
        height: 5vh;
        margin-top: 7%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .teamwar-fanemote{
        height: 3.7vh;
        margin-top: 7%;
    }
}

.tmwarfanemote-left{
    height: 15px;
}

.tmwarfan-emoteimg{
    margin-top: 30%;
    height: 35px;
    width: 35px;
}

.tmfanemoteone-count{
    margin-top: 0px;
    height: 5px;
    font: normal normal bold 16px/17px Nobel;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
}

.tmwarfanemote-right{
    height: 15px;
}

.tmfanemotetwo-count{
    text-align: end;
    margin-top: 0px;
    height: 5px;
    font: normal normal bold 16px/17px Nobel;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
}

.tmwarfanemote-hdng{
    height: 5px;
    margin-top: 0px;
}

.tmwarfanemote-txt{
    text-align: center;
    font: normal normal normal 16px/0px Nobel-Regular;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.teamwar-avghr{
    display: flex;
    justify-content: center;
    height: 6vh;
    margin-top: 5%;
}

@media screen and (max-width: 540px){
    .teamwar-avghr{
        height: 5vh;
        margin-top: 7%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .teamwar-avghr{
        height: 3.7vh;
        margin-top: 7%;
    }
}

.tmwaravghr-left{
    height: 15px;
}

.tmwaravg-hrimg{
    margin-top: 30%;
    height: 35px;
    width: 35px;
}

.tmavghrone-count{
    margin-top: 0px;
    height: 5px;
    font: normal normal bold 16px/17px Nobel;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
}

.tmwaravghr-right{
    height: 15px;
}

.tmavghrtwo-count{
    text-align: end;
    margin-top: 0px;
    height: 5px;
    font: normal normal bold 16px/17px Nobel;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
}

.tmwaravghr-hdng{
    height: 5px;
    margin-top: 0px;
}

.tmwaravghr-txt{
    text-align: center;
    font: normal normal normal 16px/0px Nobel-Regular;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.appstore-img{
    height: 40px;
}

@media screen and (max-width: 540px){
    .appstore-img{
        z-index: 5;
    }
}

.fortap-wavesession{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    margin-top: 3%;
    margin-right: 5%;
}

@media screen and (max-width: 540px){
    .fortap-wavesession{
        display: flex;
        justify-content: center;
        justify-content: space-around;
        margin-top: 2%;
        margin-right: 0%;
        margin-left: 2%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .fortap-wavesession{
        margin-top: 4%;
        margin-right: 0.5%;
        margin-left: 3%;
    }
}

.for-taps{
    z-index: 5;
    background: #274B9F 0% 0% no-repeat padding-box;
    border-radius: 16px;
    opacity: 0.85;
    height: 26vh;
    /* width: 40%; */
    width: 40%;
    margin-top: 0%;
    margin-left: 4%;
}

@media screen and (max-width: 540px){
    .for-taps{
        background: #274B9F 0% 0% no-repeat padding-box;
        border-radius: 16px;
        opacity: 0.85;
        height: auto;
        padding-bottom: 4%;
        /* width: 50%; */
        width: 100%;
        /* margin-right: 1%; */
        margin-left: 0%;
        margin-top: 0%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .for-taps{
        background: #274B9F 0% 0% no-repeat padding-box;
        border-radius: 16px;
        opacity: 0.85;
        height: 16vh;
        padding-bottom: 4%;
        /* width: 50%; */
        width: 44%;
        /* margin-right: 1%; */
        margin-left: 2%;
        margin-top: 0%;
    }
}


.for-waves{
    z-index: 5;
    background: #274B9F 0% 0% no-repeat padding-box;
    border-radius: 16px;
    opacity: 0.85;
    height: 26vh;
    width: 40%;
    margin-right: 1%;
}

@media screen and (max-width: 540px){
    .for-waves{
        /* background: #274B9F 0% 0% no-repeat padding-box;
        border-radius: 16px;
        opacity: 0.85;
        height: 22vh;
        width: 50%;
        margin-left: 1%; */
        background: #274B9F 0% 0% no-repeat padding-box;
        border-radius: 16px;
        opacity: 0.85;
        height: auto;
        padding-bottom: 4%;
        /* width: 50%; */
        width: 100%;
        /* margin-right: 1%; */
        margin-left: 4%;
        margin-top: 0%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .for-waves{
        /* background: #274B9F 0% 0% no-repeat padding-box;
        border-radius: 16px;
        opacity: 0.85;
        height: 22vh;
        width: 50%;
        margin-left: 1%; */
        background: #274B9F 0% 0% no-repeat padding-box;
        border-radius: 16px;
        opacity: 0.85;
        height: 16vh;
        padding-bottom: 4%;
        /* width: 50%; */
        width: 44%;
        /* margin-right: 1%; */
        margin-right: 2.5%;
        margin-top: 0%;
    }
}

.usertaps-img{
    margin-top: 15%;
    margin-left: 35%;
    height: 55px;
    width: 55px;
}

@media screen and (max-width: 540px){
    .usertaps-img{
        margin-top: 15%;
        margin-left: 33%;
        height: 65px;
        width: 65px;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .usertaps-img{
        margin-top: 15%;
        margin-left: 33%;
        height: 65px;
        width: 65px;
    }
}

.userwave-img{
    margin-top: 15%;
    margin-left: 35%;
    height: 55px;
    width: 55px;
}

@media screen and (max-width: 540px){
    .userwave-img{
        margin-top: 15%;
        margin-left: 33%;
        height: 65px;
        width: 65px;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .userwave-img{
        margin-top: 15%;
        margin-left: 33%;
        height: 65px;
        width: 65px;
    }
}

.usertap-counttxt{
    margin-top: 2%;
    height: 8px;
    text-align: center;
    font: normal normal bold 19px/27px Nobel;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

@media screen and (max-width: 540px){
    .usertap-counttxt{
        margin-top: 2%;
        height: 8px;
        text-align: center;
        font: normal normal bold 19px/27px Nobel;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .usertap-counttxt{
        margin-top: 2%;
        height: 8px;
        text-align: center;
        font: normal normal bold 25px/35px Nobel;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
    }
}

.userwave-counttxt{
    margin-top: 2%;
    height: 8px;
    text-align: center;
    font: normal normal bold 19px/27px Nobel;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

@media screen and (max-width: 540px){
    .userwave-counttxt{
        margin-top: 2%;
        height: 8px;
        text-align: center;
        font: normal normal bold 19px/27px Nobel;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .userwave-counttxt{
        margin-top: 2%;
        height: 8px;
        text-align: center;
        font: normal normal bold 25px/35px Nobel;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
    }
}

.userTap-counthdng{
    text-align: center;
    font: normal normal normal 18px/17px Nobel-Regular;
    letter-spacing: 0.27px;
    color: #FFFFFF;
    opacity: 1;
}

@media screen and (max-width: 540px){
    .userTap-counthdng{
        text-align: center;
        font: normal normal normal 18px/17px Nobel-Regular;
        letter-spacing: 0.27px;
        color: #FFFFFF;
        opacity: 1;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .userTap-counthdng{
        text-align: center;
        font: normal normal normal 23px/28px Nobel-Regular;
        letter-spacing: 0.27px;
        color: #FFFFFF;
        opacity: 1;
    }
}

.userwave-counthdng{
    text-align: center;
    font: normal normal normal 18px/17px Nobel-Regular;
    letter-spacing: 0.27px;
    color: #FFFFFF;
    opacity: 1;
}

@media screen and (max-width: 540px){
    .userwave-counthdng{
        text-align: center;
        font: normal normal normal 18px/17px Nobel-Regular;
        letter-spacing: 0.27px;
        color: #FFFFFF;
        opacity: 1;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .userwave-counthdng{
        text-align: center;
        font: normal normal normal 23px/28px Nobel-Regular;
        letter-spacing: 0.27px;
        color: #FFFFFF;
        opacity: 1;
    }
}

.forcamera-hr{
    width: 50%;
}

@media screen and (max-width: 540px){
.forcamera-hr{
    width: 50%;
    margin-right: 2.5%;
    margin-left: 1%;
}
}

.foraudio-files{
    position: relative;
    overflow-y: hidden;
    /* background: #274B9F 0% 0% no-repeat padding-box; */
    /* background: rgba(255, 255, 255, 0.55) 0% 0% no-repeat padding-box; */
    background: transparent linear-gradient(0deg, rgba(63, 94, 165, 1) 0%, rgba(7, 83, 234, 1) 100%) 0% 0% no-repeat padding-box;    border-radius: 16px;
    /* opacity: 0.85; */
    height: auto;
    width: 90%;
    overflow-x: hidden;
    /* margin-left: 1%; */
}

@media screen and (max-width: 540px){
    .foraudio-files{
        position: relative;
        overflow-y: hidden;
        height: auto;
        width: 99%;
        margin-right: 20%;
        padding-bottom: 1%;
        /* margin-left: 1%; */
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .foraudio-files{
        overflow-y: hidden;
        height: 16.5vh;
        width: 98%;
        margin-right: 5%;
        padding-bottom: 1%;
        margin-left: 5%;
        /* margin-left: 1%; */
    }
}

#soundLevel{
    text-align: end;
    height: 5px;
    font: normal normal bold 15px/22px Nobel;
    letter-spacing: 0.83px;
    /* color: rgba(255, 255, 255, 1); */
    color: #FFFFFF;
    /* color: #253D80; */
    opacity: 1;
    padding-right: 7%;
    padding-top: 7%;
}

#nosoundLevel{
    text-align: end;
    height: 5px;
    font: normal normal bold 15px/22px Nobel;
    letter-spacing: 0.83px;
    /* color: rgba(255, 255, 255, 1); */
    color: #FFFFFF;
    /* color: #253D80; */
    opacity: 1;
    padding-right: 7%;
    padding-top: 7%;
}

@media screen and (max-width: 540px){
    #soundLevel{
       height: 3px;
       font: normal normal bold 18px/20px Nobel;
    letter-spacing: 0.83px;
    /* color: rgba(255, 255, 255, 1); */
    color: #FFFFFF;
    /* color: #253D80; */
    opacity: 1;
    padding-right: 7%;
    padding-top: 4%;
    }
    
}

@media screen and (max-width: 540px){
    #nosoundLevel{
       height: 24px;
       font: normal normal bold 18px/20px Nobel;
    letter-spacing: 0.83px;
    color: rgba(255, 255, 255, 1);
    opacity: 1;
    padding-right: 7%;
    padding-top: 4%;
    }
    
}

.audio-bars{
    display: flex;
    justify-content: center;
    height: 80px;
    margin-top: 0%;
    /* justify-content: space-around; */
}

.sound-lottie{
    height: 105px;
    width: 115px;
}

.audiobar-setting{
    width: 100%;
    height: 31px;
}

@media screen and (max-width: 540px){
    .audiobar-setting{
        width: 100%;
        height: 42px;
    }
}

.startbutton-audiometer{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    margin-top: 7%;
}

@media screen and (max-width: 540px){
    .startbutton-audiometer{
        margin-top: 4.7%;
    }
}

.mic-image{
    position: absolute;
    bottom: 39.5%;
}

@media screen and (max-width: 540px){
    .mic-image{
        position: absolute;
        bottom: 44%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .mic-image{
        position: absolute;
        bottom: 46%;
    }
}

.soundmeter-heading{
    padding-top: 0%;
    text-align: center;
    font: normal normal normal 16px/37px Nobel-Regular;
    letter-spacing: 0px;
    color: #FFFFFF;
    /* color: #253D80; */
    opacity: 1;
}

@media screen and (max-width: 540px){
    .soundmeter-heading{
        padding-top: 12%;
        padding-bottom: 1%;
        text-align: center;
        font: normal normal normal 16px/3px Nobel-Regular;
        letter-spacing: 0px;
        color: #FFFFFF;
        /* color: #253D80; */
        opacity: 1;
    }
}

.second-firstalign{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    margin-right: 10%;
    margin-left: 10%;
    margin-top: 3%;
}

@media screen and (max-width: 540px){
    .second-firstalign{
        display: flex;
        justify-content: center;
        justify-content: space-around;
        margin-right: 0%;
        margin-left: 1%;
        margin-top: 0%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .second-firstalign{
        display: flex;
        justify-content: center;
        justify-content: space-around;
        margin-right: 1%;
        margin-left: 1%;
        margin-top: 3%;
    }
}

.for-audiofiles{
    width: 50%;
    margin-left: 0%;
}

@media screen and (max-width: 540px){
    .for-audiofiles{
        width: 50%;
        margin-left: 0%;
        margin-right: 0.5%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .for-audiofiles{
        width: 50%;
        margin-left: 3%;
    }
}

/* @media screen and (max-width: 1200px){
    .for-audiofiles{
        width: 60%;
        margin-left: 10%;
    }
} */

/* @media screen and (max-width: 540px){
    .second-firstalign{
        margin-top: 2%;
    }
} */

.heart-lottie{
    position: absolute;
    bottom: 1%;
    left: 42%;
    height: 60vh;
    /* width: 30%; */
}

@media screen and (max-width: 540px){
    .heart-lottie{
        position: absolute;
        bottom: 1%;
        left: 0%;
        height: 99vh;
        /* width: 70%; */
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .heart-lottie{
        position: absolute;
        bottom: 1%;
        left: 22%;
        height: 60vh;
        /* width: 30%; */
    }
}

.event-lottie{
    z-index: 5;
    position: absolute;
    left: 40%;
    bottom: 15%;
    height: 220px;
    width: 210px;
}

@media screen and (max-width: 540px){
    .event-lottie{
        z-index: 5;
        position: absolute;
        left: 20%;
        bottom: 44%;
        height: 210px;
        width: 270px;
    }
}

@media screen and (max-width: 540px){
    .event-lottie{
        z-index: 5;
        position: absolute;
        left: 20%;
        bottom: 44%;
        height: 210px;
        width: 270px;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .event-lottie{
        z-index: 5;
        position: absolute;
        left: 40%;
        bottom: 44%;
        height: 210px;
        width: 270px;
    }
}


.fanplay-index{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 5%;
    left: 45%;
}

@media screen and (max-width: 540px){
    .fanplay-index{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 3.5%;
        left: 35%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .fanplay-index{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 4%;
        left: 43%;
    }
}

.fanplay-indextxt{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0%;
    left: 44%;
}

@media screen and (max-width: 540px){
    .fanplay-indextxt{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: -0%;
        left: 32%;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .fanplay-indextxt{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 1%;
        left: 40%;
    }
}

.fanplayindex-txt{
    text-align: center;
    font: normal normal normal 10px/14px Nobel;
    letter-spacing: 0px;
    color: #515151;
    z-index: 5;
    opacity: 1;
}

@media screen and (max-width: 540px){
.fanplayindex-txt{
    text-align: center;
    font: normal normal normal 10px/12px Nobel;
    letter-spacing: 0px;
    color: #515151;
    z-index: 5;
    opacity: 1;
}
}

@media (min-width: 740px) and (max-width: 940px){
    .fanplayindex-txt{
        text-align: center;
        font: normal normal normal 14px/13px Nobel;
        letter-spacing: 0px;
        color: #515151;
        z-index: 5;
        opacity: 1;
    }
}


.index-fanplaylogo{
    /* margin-top: 3%; */
    height: 35px;
    width: 119px;
    z-index: 5;
}

@media (min-width: 740px) and (max-width: 940px){
    .index-fanplaylogo{
        /* margin-top: 3%; */
        height: 57px;
        width: 139px;
        z-index: 5;
    }
}

.habbabg-img{
    position: absolute;
    left: 32%;
    top: 16%;
    height: 490px;
    width: 490px;
}

@media screen and (max-width: 540px){
    .habbabg-img{
        position: absolute;
        left: 10%;
        top: 26%;
        height: 320px;
        width: 320px;
    }
}


