/* .mySwiper{
    width: 100%;
    height: 18vh;
    margin-left: 0%;
    border-radius: 16px;
}

@media screen and (max-width: 540px){
    .mySwiper{
        z-index: 5;
        width: 100%;
        height: auto;
        padding-bottom: 2%;
        border-radius: 16px;
    }
}

@media (min-width: 740px) and (max-width: 940px){
    .mySwiper{
        z-index: 5;
        width: 90%;
        height: 10vh;
        margin-left: 10%;
        padding-bottom: 2%;
        border-radius: 16px;
    }
}

.swiper .swiper-initialized .swiper-horizontal .swiper-backface-hidden .mySwiper{
    width: 30%;
}

.swiperalign{
    display: flex;
    justify-content: center;
    justify-content: space-around;
}

.swiper-3d {
    perspective: none;
}


.swiper-3d .swiper-wrapper {
    transform-style: none;
}

.swiper-pagination-bullet-active{
    background: transparent linear-gradient(180deg, #B2E800 0%, #64AD00 100%) 0% 0% no-repeat padding-box;
} */

.mySwiper{
    width: 100%;
    height: 46vh;
    margin-left:0%;
    /* margin-right: 10%; */
    border-radius: 16px;
}

.swiper .swiper-initialized .swiper-horizontal .swiper-backface-hidden .mySwiper{
    width: 30%;
}

.swiperalign{
    display: flex;
    justify-content: center;
    justify-content: space-around;
}

.swiper-3d {
    perspective: none;
}


.swiper-3d .swiper-wrapper {
    transform-style: none;
}

.swiper-pagination-bullet-active{
    /* background-color: #B2E800; */
    /* background: #33942D 0% 0% no-repeat padding-box; */
    background: #000000 0% 0% no-repeat padding-box;
}